import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './errorview.component.html',
  styleUrls: ['./errorview.component.scss']
})
export class ErrorViewComponent {
  public logo: string = environment.logo;
}
