import { Component } from '@angular/core';
import { NewAuthService } from 'src/app/services/oauth.service';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/services/language.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  templateUrl: './signonview.component.html',
  styleUrls: ['./signonview.component.scss']
})
export class SignOnViewComponent {
  public logo: string = environment.logo;
  public message: string = null;
  public isError: boolean = false;

  constructor(private oauthService: NewAuthService) {
    console.log('Redirecting from Sign-on to OAuth login');
    this.onSubmit();
  }

  public onSubmit(): void {
    this.message = null;
    this.isError = false;
    this.oauthService.login();
  }

  get token() {
    return this.oauthService.getToken();
  }
}
