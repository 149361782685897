import { Component, OnInit, OnDestroy } from '@angular/core';
import { Compactor, CompactorAlarms, CompactorAlarm, CompactorAlarmTemplates, CompactorAlarmTemplate, CompactorAlarmAction } from 'src/app/models/device';
import { Recipient } from 'src/app/models/device';
import { DeviceAPIService } from 'src/app/services/device-api.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, FormArray, Validators, ValidationErrors } from '@angular/forms';
import { LanguageService } from 'src/app/services/language.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SingleAlarmAction } from 'src/app/models/device';

// Component for viewing and editting e-mail notifications for a device
@Component({
  templateUrl: './notificationsview.component.html',
  styleUrls: ['./notificationsview.component.scss']
})
export class NotificationsViewComponent implements OnInit, OnDestroy {
  private device_id: string;
  private subscriptions: Subscription[] = [];
  public device$: Observable<Compactor>;
  private device: Compactor;

  public alarms$: Observable<CompactorAlarms>;
  public alarms: CompactorAlarms = [];

  public alarm$: Observable<CompactorAlarm>;
  public alarm: CompactorAlarm;

  public templates$: Observable<CompactorAlarmTemplates>;
  public templates: CompactorAlarmTemplates;
  public selectedTemplate: CompactorAlarmTemplate;

  public newAlarmForm: FormGroup;
  public eventTypes: string[];
  public alarmTypes: string[];
  public submitResponse: string;
  public showAmounts: string[];

  public alarmSorting: string = 'alarm';
  public alarmShowMax: string = 'showAll';
  public alarmOrder: string = 'DESC'; // descending

  public presets = [];
  public selectedAlarm: CompactorAlarmAction;
  public recipientsCount = 0; // first recipient row gets added in resetForm()

  constructor(private deviceService: DeviceAPIService,
              private activatedRoute: ActivatedRoute,
              private languageService: LanguageService,
              private formBuilder: FormBuilder) {
    this.device_id = this.activatedRoute.snapshot.params.id;

    // retrieving data from the API
    this.device$ = this.deviceService.getOne(this.device_id);
    // this.alarms$ = this.deviceService.getAlarms(this.device_id);
    this.alarm$ = this.deviceService.getAlarm(this.device_id);
    this.templates$ = this.deviceService.getTemplates();

    // create an empty form for submitting new Alarms
    this.selectedAlarm = null;
    this.eventTypes = ['EMERGENCY', 'HITCH', 'POWEROFF', 'POWERON', 'CONTAINERGONE',
      'CONTAINERCOUPLED', 'ALMOSTFULL', 'FULL', 'MAINTENANCE', 'ALL'];
    this.alarmTypes = ['EMAIL']; // as described in the API
    // TODO: Define these enums in the Device Model instead of here
    this.showAmounts = ['5', '10', '25', '50']; // all gets added in the HTML
    this.submitResponse = '';
    this.resetForm();
  }

  changeTemplate(template_id: number) {
    if (this.templates) {
      const templatesArray: CompactorAlarmTemplate[] = this.templates.templates;
      this.selectedTemplate = templatesArray.find(o => o.id == template_id);
      // There is a small bug here (probably in number-string conversion, the .find fails with === compare)
    }
  }

  // TODO: This is bit of an ugly solution and 100 is an arbitrary number
  isBigTemplate() {
    return this.selectedTemplate.body.length > 100;
  }

  // Submitting form for setting a new Alarm
  onSubmit(newAlarmForm: FormGroup) {
    const type: string = newAlarmForm.get('type').value;
    const event: string = newAlarmForm.get('event').value;
    const template_id: string = newAlarmForm.get('template_id').value;

    // Validate form data
    if (!type) {
      this.submitResponse = this.languageService.getTranslation(_('alarms.invalidAlarmType'));
      return;
    }
    if (!event) {
      this.submitResponse = this.languageService.getTranslation(_('alarms.invalidAlarmEvent'));
      return;
    }
    const formRecipients = newAlarmForm.get('recipients').value;
    const recipientsArray: Recipient[] = [];

    for (let i = 0; i < formRecipients.length; i++) {
      const mail = formRecipients[i].email;
      const name = formRecipients[i].name;
      const newRec: Recipient = {address: mail, name};
      recipientsArray.push(newRec);
    }

    let alarmData: SingleAlarmAction;
    if (this.selectedAlarm == null) {
      // no alarm selected, posting NEW
      if (template_id !== null && template_id !== '0') {
        alarmData = {action: {type, event, recipients: recipientsArray, template_id}}; // include template_id
      } else {
        alarmData = {action: {type, event, recipients: recipientsArray}}; // send no template_id
      }
      const request: Observable<object> = this.deviceService.postNewAlarm(this.device_id, alarmData);
      request.subscribe(
        (reqResponse) => {
          // console.log('HTTP Response: ' + reqResponse + ' | ' + JSON.stringify(reqResponse));
        },
        (reqError) => {
          // console.log('HTTP Error: ' + reqError + ' | ' + JSON.stringify(reqError));
          this.submitResponse = this.languageService.getTranslation(_('alarms.submittedAlarmFailed'));
        },
        () => {
          this.refreshData();
          this.resetForm();
          this.submitResponse = this.languageService.getTranslation(_('alarms.submittedAlarm'));
          this.selectedTemplate = null;
          this.selectedAlarm = null;
       });
    } else {
      // alarm selected, updating (POST)
      if (template_id !== null && template_id !== '0') {
        alarmData = {action: {id: this.selectedAlarm.id, type, event, recipients: recipientsArray, template_id}}; // include template_id
      } else {
        alarmData = {action: {id: this.selectedAlarm.id, type, event, recipients: recipientsArray}}; // send no template_id
      }
      const request: Observable<object> = this.deviceService.updateAlarm(this.device_id, alarmData, this.selectedAlarm.id);
      request.subscribe(
        (reqResponse) => {
          // console.log('HTTP Response: ' + reqResponse + ' | ' + JSON.stringify(reqResponse));
        },
        (reqError) => {
          // console.log('HTTP Error: ' + reqError + ' | ' + JSON.stringify(reqError));
          this.submitResponse = this.languageService.getTranslation(_('alarms.updatedAlarmFailed'));
        },
        () => {
          this.refreshData();
          this.resetForm();
          this.submitResponse = this.languageService.getTranslation(_('alarms.updatedAlarm'));
          this.selectedTemplate = null;
          this.selectedAlarm = null;
       });
    }
  }

  public recipients() {
    const newRecipients: FormArray = this.newAlarmForm.controls.recipients as FormArray;
    return newRecipients.controls;
  }

  // Create the output for all recipients in the format: Mail (Name), Mail (Name)
  public showRecipients(recipients: Recipient[]) {
    let result = '';
    if (recipients == null) {
      return result;
    }
    for (let i = 0; i < recipients.length; i++) {
      result += ' ' + recipients[i].address + (recipients[i].name ? ' (' + recipients[i].name + ')' : '')
        + (i < recipients.length - 1 ? ', ' : '');
    }
    return result;
  }

  // Reset the Form
  public resetForm() {
    // console.log("Resetting form");
    this.recipientsCount = 0;
    this.newAlarmForm = this.formBuilder.group({
      event: ['', Validators.required],
      type: ['EMAIL', Validators.required],
      recipients: new FormArray([]),
      template_id: ['0', Validators.required]
    });
    this.addRecipient();
    this.changeTemplate(0); // default template
    this.submitResponse = null;
  }

  // Set the current sorting colum for Alarms to the given one, and switch the order if we clicked on the same one
  public setAlarmSorting(order: string) {
    if (this.alarmSorting === order) {
      // Switch sorting order of the Alarms table between ASC and DESC
      this.alarmOrder = (this.alarmOrder === 'ASC' ? 'DESC' : 'ASC');
    }
    this.alarmSorting = order;
  }

  private loadPresets() {
    this.presets = [];
    if (this.alarm == null || this.alarm.actions == null) {
      return;
    }
    for (let i = 0; i < this.alarm.actions.length; i++) {
      const action = this.alarm.actions[i];
      const recipients = action['recipients'];
      for (let j = 0; j < recipients.length; j++) {
        const recipient = recipients[j];
        let name: string = recipient['name'];
        if (name != null && name.length > 100) {
          // prevent the pulldown from being really wide
          name = name.substr(0, 100);
        }
        const address: string = recipient['address'];
        const element = {name, address};
        // prevent duplicates
        if (!this.presets.some(code => code.name === name && code.address === address)) {
          // pretty but not sure if this check for duplicats is the most efficent
          this.presets.push(element);
        }
      }
    }
  }

  ngOnInit() {
    this.subscriptions.push(this.device$.subscribe(d => this.device = d));
    this.subscriptions.push(this.alarm$.subscribe(a => { this.alarm = a; this.loadPresets(); }));
    this.subscriptions.push(this.templates$.subscribe(d => { this.templates = d; }));
  }

  ngOnDestroy() {
    // Unsubscribe all subscriptions, to prevent continuous creation of new subscriptions as navigation changes between views
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.length = 0;
  }

   // Returns the correct ASC or DESC fa icon (or none)
   public getIcon(column: string) {
    if (this.alarmSorting === column) {
      return this.alarmOrder === 'ASC' ? 'fa-sort-up' : 'fa-sort-down';
    } else {
      return '';
    }
  }

  public refreshData(): void {
    this.alarm$ = this.deviceService.getAlarm(this.device_id);
  }

  // If there is only 1 recipient left, remove delete button
  public showDeleteButton() {
    return this.recipientsCount > 1;
  }

  // === USER ACTIONS FROM HERE ==

  // User selected a preset recipient
  public selectPreset(index: number, presetIndex: any) {
    // console.log("Selected preset: "+ presetIndex);
    // console.log("Updating recipient " + index);
    const selectedPreset = this.presets[presetIndex];
    const newRecipients: FormArray = this.newAlarmForm.controls.recipients as FormArray;
    const newForm = this.formBuilder.group({
      email: [selectedPreset['address'], [Validators.required, Validators.email]],
      name: [selectedPreset['name']]});
    newRecipients.setControl(index, newForm);
  }

  // User clicked DEL button, removing row 'i' from the recipients list
  public removeRecipient(i: number) {
    const newRecipients: FormArray = this.newAlarmForm.controls.recipients as FormArray;
    newRecipients.removeAt(i);
    this.recipientsCount--;
  }

  // User clicked ADD button, add another recipient row
  public addRecipient(): void {
    this.recipientsCount++;
    const newRecipients: FormArray = this.newAlarmForm.controls.recipients as FormArray;
    newRecipients.push(this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['']
     }));
  }

  // User clicked DELETE button, removing Alarm
  public deleteAlarm(alarm_id: string) {
    this.cancelEdit();
    // console.log("Deleting alarm " + alarm_id);
    const request: Observable<object> =  this.deviceService.deleteAlarm(this.device_id, alarm_id);
    request.subscribe(
      (reqResponse) => {
        // console.log('HTTP Response: ' + reqResponse + ' | ' + JSON.stringify(reqResponse));
      },
      (reqError) => {
        // console.log('HTTP Error: ' + reqError + ' | ' + JSON.stringify(reqError));
        this.submitResponse = this.languageService.getTranslation(_('alarms.deleteAlarmFailed'));
      },
      () => {
        // console.log('HTTP Post Log Completed.');
        this.selectedAlarm = null;
        this.refreshData();
        this.resetForm();
        this.submitResponse = this.languageService.getTranslation(_('alarms.deletedAlarm'));
     });
  }

  // User clicked on an ALARM to edit is
  public clickAlarm(alarm: CompactorAlarmAction) {
    this.resetForm();
    this.submitResponse = '';
    if (alarm != null) {
      this.selectedAlarm = alarm;
      this.newAlarmForm = this.formBuilder.group({
          event: alarm.event,
          type: alarm.type,
          template_id: alarm.template_id ? alarm.template_id : '0',
          recipients: new FormArray([])}
      );
      if (alarm.template_id) { // update template
        this.changeTemplate(Number(alarm.template_id));
      } else { // no template selected
        this.selectedTemplate = null;
      }
      const newRecipients: FormArray = this.newAlarmForm.controls.recipients as FormArray;
      if (alarm.recipients != null) {
        this.recipientsCount = alarm.recipients.length;
        for (let i = 0; i < alarm.recipients.length; i++ ) {
          const recipient = alarm.recipients[i];
          newRecipients.push(this.formBuilder.group({
            email: [recipient.address, [Validators.required, Validators.email]],
            name: [recipient.name ? recipient.name : '']
          }));
        }
      }
    }
  }

  // User clicked on Cancel button when editting (or X icon)
  public cancelEdit() {
    this.resetForm();
    this.selectedAlarm = null;
    this.submitResponse = '';
  }

  // Translate Alarm Type ("EMAIL", "TELEGRAM") to human readable "e-mail", etc.
  public translateType(type: string) {
    let translated: string;
    switch (type) {
      case 'EMAIL': translated = this.languageService.getTranslation(_('settings.email')); break;
    }
    if (!translated) {
      // console.log('Unknown Notification Type: ' + type);
      return type;
    } else {
      return translated;
    }
  }

  // Trasnlate Event Name ("HitchOn", etc"), if one is available
  public translateEvent(event: string) {
   let translated: string;
   switch (event) {
      case 'EMERGENCY': translated = this.languageService.getTranslation(_('event.EmergencyOn')); break;
      case 'HITCH': translated = this.languageService.getTranslation(_('event.HitchOn')); break;
      case 'FULL': translated = this.languageService.getTranslation(_('event.ContainerFull')); break;
      case 'CONTAINERGONE': translated = this.languageService.getTranslation(_('event.ContainerGone')); break;
      case 'CONTAINERCOUPLED': translated = this.languageService.getTranslation(_('event.ContainerOn')); break;
      case 'POWERON': translated = this.languageService.getTranslation(_('event.PowerOn')); break;
      case 'POWEROFF': translated = this.languageService.getTranslation(_('event.PowerOff')); break;
      case 'ALMOSTFULL': translated = this.languageService.getTranslation(_('event.AlmostFull')); break;
      case 'MAINTENANCE': translated = this.languageService.getTranslation(_('event.Maintenance')); break;
      case 'ALL': translated = this.languageService.getTranslation(_('event.allEvents')); break;
    }
   if (!translated) {
      // console.log('Unknown Alarm Event: ' + event);
    }
   return translated ? translated : this.languageService.getTranslation(_('event.Unknown'));
  }

  // When previewing the HTML it would be nice to replace the tags with actual data
  public replaceBodyTags(html: string) {
    const formRecipients = this.newAlarmForm.get('recipients').value;
    const recipientName = formRecipients.length > 0 ? formRecipients[0].name : 'Name';
    const recipient = this.boldify(recipientName);
    const selectedEvent = this.newAlarmForm.get('event').value ? this.newAlarmForm.get('event').value : 'Event';
    const eventName = this.boldify(this.translateEvent(selectedEvent));
    const device = this.boldify(this.device.compactor.name);
    const timestamp = this.boldify(new Date().toDateString());
    const fillGrade = this.boldify(this.device.compactor.percentage.toString());
    const fraction = this.boldify(this.device.compactor.fraction
      ? this.device.compactor.fraction.name
      : 'Fraction');
    const customer = this.boldify(this.device.compactor.customer
      ? this.device.compactor.customer.name
      : 'Customer');
    const address = this.boldify(this.device.compactor.address
      ? this.device.compactor.address.street + ' - ' + this.device.compactor.address.city
      : 'Address');
    html = html.replace('<table ', '<table class="html" ');
    html = html.replace('<table cellpadding=0', '<table class="html" cellpadding=0');
    html = html.replace('<table cellpadding="0"', '<table class="html" cellpadding="0"');
    html = html.replace('<table width="100%"', '<table class="html" width="100%"');
    html = html.replace('<table width=100%', '<table class="html" width=100%');
    html = html.replace('{{.Name}}', recipient);
    html = html.replace('{{.Event.Name}}', this.device.compactor.name);
    html = html.replace('{{.Event.Name}}', device);
    html = html.replace('{{.Event.Type | dutch}}', eventName);
    html = html.replace('{{.Event.Type | english}}', eventName);
    html = html.replace('{{.Event.Timestamp | totime}}', timestamp);
    html = html.replace('{{.Event.Fraction}}', fraction);
    html = html.replace('{{.Event.Customer}}', customer);
    html = html.replace('{{.Event.Address | htmler}}', address);
    html = html.replace('{{printf "%.1f" .Event.Value}}', fillGrade);
    for (let i = 0; i < 4; i++) {
      html = html.replace('{{end}}', '');
    }
    html = html.replace('{{if .Event.Value}}', '');
    html = html.replace('{{if .Event.Address}}', '');
    html = html.replace('{{if .Event.Fraction}}', '');
    html = html.replace('{{if .Event.Customer}}', '');
    return html;
  }

  // When previewing the HTML it would be nice to replace the tags with actual data
  public replaceSubjectTags(html: string) {
    const selectedEvent = this.newAlarmForm.get('event').value ? this.newAlarmForm.get('event').value : 'Event';
    const eventName = this.translateEvent(selectedEvent);
    const device = this.device.compactor.name;
    html = html.replace('{{.Event.Name}}', device);
    html = html.replace('{{.Event.Type}}', eventName);
    return html;
  }

  // Make a string bold
  private boldify(str: string) {
    return '<b>' + str + '</b>';
  }
}
