import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceAPIService } from 'src/app/services/device-api.service';
import { LanguageService } from 'src/app/services/language.service';
import { Compactor, CompactorLog, CompactorLogs, SingleCompactorLog } from 'src/app/models/device';
import { CompactorInspection, CompactorInspections } from 'src/app/models/device';
import { Observable, Subscription } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  templateUrl: './serviceview.component.html',
  styleUrls: ['./serviceview.component.scss']
})
export class ServiceViewComponent implements OnInit, OnDestroy {

  // Data
  private device: Compactor;
  private device_id: string;
  private subscriptions: Subscription[] = [];
  public device$: Observable<Compactor>;
  public inspections$: Observable<CompactorInspections>;
  private inspections: CompactorInspections;
  public log$: Observable<CompactorLogs>;
  private log: CompactorLog[];
  public logsPeriod: string = 'DEFAULT'; // DEFAULT, DAY, WEEK, MONTH, MONTHS-3

  // Forms
  public newLogForm: FormGroup;
  public newInspectionForm: FormGroup;
  public newLogItem: boolean; // true when creating new issue, false when editing/updating
  public today: string; // timestamp of the current day
  public selectedLogItem: CompactorLog;
  public selectedInspection: CompactorInspection;

  // Date fields
  public created_at_date;
  public modified_at_date;
  public inspection_timestamp_last;
  public inspection_timestamp_next;

  // Sorting
  public logSorting: string = 'created_at';
  public logOrder: string = 'DESC'; // descending
  public serviceSorting: string = 'type';
  public serviceOrder: string = 'DESC'; // descending

  constructor(private deviceService: DeviceAPIService,
              private activatedRoute: ActivatedRoute,
              private languageService: LanguageService,
              private formBuilder: FormBuilder,
              private utils: UtilsService) {
    this.device_id = this.activatedRoute.snapshot.params.id;
    this.device$ = this.deviceService.getOne(this.device_id);
    this.retrieveLogs();
    this.retrieveInspections();
    this.newLogItem = true;
    this.selectedInspection = null;
    this.selectedLogItem = null;
    const now = Math.round(new Date().getTime());
    this.today = now.toString();
    this.resetLogForm();
    this.resetInspectionForm();
  }

  public retrieveLogs() {
    if (this.logsPeriod === 'DEFAULT' || this.logsPeriod === 'ALL') {
      // TODO: Make distinction between DEFAULT / ALL options (or remove one of them)
      this.log$ = this.deviceService.getLog(this.device_id);
    } else {
      const timestamp = this.utils.getTimestamp(this.logsPeriod);
      console.log('Period: ' + this.logsPeriod + ' - Timestamp: ' + timestamp);
      this.log$ = this.deviceService.getLogItemsFrom(this.device_id, timestamp);
    }
  }

  private retrieveInspections() {
    this.inspections$ = this.deviceService.getInspections(this.device_id);
  }

  ngOnInit() {
    this.subscriptions.push(this.device$.subscribe((device: Compactor) => {
      this.device = device;
      // console.log('Device received: ' + JSON.stringify(device));
    }));
    this.subscriptions.push(this.log$.subscribe((log: CompactorLogs) => {
      this.log = log.logitems;
      // console.log('Logs received: ' + JSON.stringify(log));
    }));
    this.subscriptions.push(this.inspections$.subscribe((inspections: CompactorInspections) => {
      this.inspections = inspections;
      // console.log('Inspections received: ' + JSON.stringify(inspections));
      const servicesCount = inspections && inspections.service ? inspections.service.length : 0;
      // console.log("# services: " + temp);
      if (servicesCount === 0) {
        // when no services are found we create them (for now just NEN keuring)
        this.resetInspections();
      }
    }));
  }

  ngOnDestroy() {
    // Unsubscribe all subscriptions, to prevent continuous creation of new subscriptions as navigation changes between views
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.length = 0;
  }

  // Reset the Log Entry Form to empty data
  public resetLogForm() {
    // console.log('Resetting form.');
    this.newLogForm = this.formBuilder.group({
      issuer: ['', Validators.required],
      issue: ['', Validators.required],
      created_at: ['', Validators.required],
      modified_at: [''],
      response: [''],
      solved: [''],
    }, {
      validators: this.dateValidator()
    });
  }

  // {validator: this.dateValidator('created_at', 'modified_at')});
  // Reset the Inspection Form to empty data
  public resetInspectionForm() {
    this.newInspectionForm = this.formBuilder.group({
      type: ['', Validators.required],
      timestamp_last: ['', Validators.required],
      timestamp_next: ['', Validators.required]
    });
  }

  public onSubmitInspections(newInspectionForm: FormGroup) {
    const timestamp_last = Date.parse(this.inspection_timestamp_last);
    const timestamp_next = Date.parse(this.inspection_timestamp_next);
    const selectedType: string = newInspectionForm.get('type').value;
    for (const k in this.inspections.service) {
      if (this.inspections.service.hasOwnProperty(k)) {
        const i = this.inspections.service[k];
        if (i.type === selectedType) {
          // update dates for this service item
          // console.log("timestamp last: " + timestamp_last);
          // console.log("timestamp next: " + timestamp_next);
          if (timestamp_last) {
            this.inspections.service[k].timestamp_last = timestamp_last;
          }
          if (timestamp_next) {
            this.inspections.service[k].timestamp_next = timestamp_next;
          }
        }
      }
    }
    const request: Observable<object> = this.deviceService.updateInspections(this.device_id, this.inspections);
    request.subscribe(
      (response) => {
        // console.log('HTTP Response: ' + response + ' | ' + JSON.stringify(response));
      },
      (error) => {
        console.log('HTTP Error: ' + error + ' | ' + JSON.stringify(error));
      },
      () => {
        // console.log('HTTP PUT Inspection Completed.');
        this.retrieveInspections();
     });
  }

  // User submitted a new log entry
  public onSubmit(logForm: FormGroup) {
    // console.log('Submitting new log entry.');
    const issuer: string = logForm.get('issuer') != null ? logForm.get('issuer').value : '';
    const issue: string = logForm.get('issue') != null ? logForm.get('issue').value : '';
    const response: string = logForm.get('response') != null ? logForm.get('response').value : '';
    const solvedString = logForm.get('solved') != null ? logForm.get('solved').value : '';
    const solved = (solvedString === '1' ? true : false);
    // console.log('Created at: ' + this.created_at_date);
    // console.log('Modified at: ' + this.modified_at_date);
    const created_at = Date.parse(this.created_at_date);
    const modified_at = Date.parse(this.modified_at_date);
    // console.log('Created at: ' + created_at);
    // console.log('Modified at: ' + modified_at);
    const newLogEntry: SingleCompactorLog = {
      logitem: {
        created_at, modified_at, issuer, issue, response, solved
      }
    };
    let request: Observable<object>;
    if (this.newLogItem) {
      // Posting new log item
      // console.log('Posting New Log item: ' + JSON.stringify(newLogEntry));
      request = this.deviceService.postNewLog(this.device_id, newLogEntry);
    } else {
      // Editing existing log item
      // console.log('Putting Existing Log item: ' + JSON.stringify(newLogEntry));
      request = this.deviceService.updateLog(this.device_id, newLogEntry, this.selectedLogItem.id);
    }
    request.subscribe(
      (reqResponse) => {
        // console.log('HTTP Response: ' + reqResponse + ' | ' + JSON.stringify(reqResponse));
      },
      (reqError) => {
        console.log('HTTP Error: ' + reqError + ' | ' + JSON.stringify(reqError));
      },
      () => {
        // console.log('HTTP Post Log Completed.');
        this.retrieveLogs();
     });
  }

  // User clicked DELETE button, removing Alarm
  public deleteLog(log_id: number) {
    // this.cancelEdit();
    // console.log('Deleting log entry with id: ' + log_id);
    const request: Observable<object> = this.deviceService.deleteLog(this.device_id, log_id);
    request.subscribe(
      (response) => {
        // console.log('HTTP Response: ' + response + ' | ' + JSON.stringify(response));
      },
      (error) => {
        console.log('HTTP Error: ' + error + ' | ' + JSON.stringify(error));
      },
      () => {
        // console.log('HTTP Post Log Completed.');
        this.retrieveLogs();
     });
  }

  // User Clicked CANCEL button in Inspection Form, cancelling editting and removing form
  public cancelInspectionEdit() {
    this.resetInspectionForm();
    this.selectedInspection = null;
  }

  // User clicked CANCEL button in Log Form, cancelling editting and resetting form
  public cancelLogEdit() {
    // console.log('Cancelling editting entry.');
    this.resetLogForm();
    this.newLogItem = true;
    this.selectedLogItem = null;
  }

  // User clicked an Inspection Entry row, so selecting them to EDIT
  public clickInspection(item: CompactorInspection) {
    this.selectedInspection = item;
    // converting dates to the format the datepicker expects (there might be a more elegant solution)
    const timestamp_last_fixed = item.timestamp_last ? new Date(item.timestamp_last).toISOString().substring(0, 10) : '';
    const timestamp_next_fixed = item.timestamp_next ? new Date(item.timestamp_next).toISOString().substring(0, 10) : '';
    // update Form
    (async () => {
      await this.delay(50); // not quite sure why we need this but form works better this way
      // console.log("Last: " + timestamp_last_fixed);
      // console.log("Next: " + timestamp_next_fixed);
      this.newInspectionForm = this.formBuilder.group({
        type: [item.type, Validators.required],
        timestamp_last: [timestamp_last_fixed, Validators.required],
        timestamp_next: [timestamp_next_fixed, Validators.required]
      });
    })();
  }

  // User clicked a LOG ENTRY row, so selecting them to EDIT
  public clickLog(log_item: CompactorLog) {
    // console.log('Editing log entry.');
    this.newLogItem = false;
    this.selectedLogItem = log_item;
    // converting date to the format the datepicker expects (there might be a more elegant solution)
    const modifiedDate = log_item.modified_at ? new Date(log_item.modified_at).toISOString().substring(0, 10) : '';
    // update Form
    this.newLogForm = this.formBuilder.group({
      issuer: [log_item.issuer, Validators.required],
      issue: [log_item.issue, Validators.required],
      created_at: [log_item.created_at, Validators.required],
      modified_at: [modifiedDate],
      response: [log_item.response],
      solved: [log_item.solved ? 1 : 0],
    }, {
      validators: this.dateValidator()
    });
  }

  // Validating the repsonse date: form is invalid when response date is before create date
  private dateValidator() {
    return (group: FormGroup): {[key: string]: any} => {
      const f = group.controls['created_at'];
      const t = group.controls['modified_at'];
      if (f == null || t == null || !f.value || !t.value) {
        // console.log('Can\'t compare dates, one or both are null.');
        return;
      }
      const from = new Date(f.value)?.toISOString()?.substring(0, 10);
      const to = new Date(t.value)?.toISOString()?.substring(0, 10);
      if (from > to) {
        return {
          dates: 'Response Date should be after the Issue Creation Date'
        };
      }
      return;
    };
  }

  // Returns the correct ASC or DESC fa icon (or none) for the Log Table headers
  public getLogSortIcon(column: string) {
    if (this.logSorting === column) {
      return this.logOrder === 'ASC' ? 'fa-sort-up up' : 'fa-sort-down down';
    } else {
      return '';
    }
  }

  // Returns the correct ASC or DESC fa icon (or none) for the Log Table headers
  public getServiceSortIcon(column: string) {
    if (this.serviceSorting === column) {
      return this.serviceOrder === 'ASC' ? 'fa-sort-up up' : 'fa-sort-down down';
    } else {
      return '';
    }
  }

  // Set the current sorting colum for Log to the given one, and switch the order (ASC/DESC) if we clicked on the same one
  public setLogSorting(order: string) {
    if (this.logSorting === order) {
      // Switch sorting order of the Events table between ASC and DESC
      this.logOrder = (this.logOrder === 'ASC' ? 'DESC' : 'ASC');
    }
    this.logSorting = order;
  }

  // Set the current sorting colum for Inspections to the given one, and switch the order (ASC/DESC) if we clicked on the same one
  public setServiceSorting(order: string) {
    if (this.serviceSorting === order) {
      // Switch sorting order of the Events table between ASC and DESC
      this.serviceOrder = (this.serviceOrder === 'ASC' ? 'DESC' : 'ASC');
    }
    this.serviceSorting = order;
  }

  // Turn the solved boolean into a translatable human readable String ('yes', 'no')
  public getSolvedString(solved: boolean): string {
    switch (solved) {
      case true: return this.languageService.getTranslation(_('common.yes'));
      case false: return this.languageService.getTranslation(_('common.no'));
    }
  }

  // Shorten the issue/response text to a max and add ... after it (click or hover the field shows all contents)
  public shorten(text: string) {
    const MAX_LENGTH = 29;
    if (text && text.length > MAX_LENGTH) {
      return text.substring(0, MAX_LENGTH) + '...';
    } else {
      return text;
    }
  }

  // Will try to translate the given inspection type if possible
  public translateInspectionType(text: string) {
    switch (text) {
      case 'Maintenance': return this.languageService.getTranslation(_('service.inspections.maintenance'));
      case 'NEN': return this.languageService.getTranslation(_('service.inspections.NEN'));
      default: return text;
    }
  }

  // Reset maintenance / nen keuring
  private resetInspections() {
    const newInspections: CompactorInspections = { service: [
      { type: 'NEN',         timestamp_last: 1615311409000, timestamp_next: 1615311409000},
      { type: 'Maintenance', timestamp_last: 1615311409000, timestamp_next: 1615311409000},
    ]};
    // console.log(JSON.stringify(newInspections));
    const request: Observable<object> = this.deviceService.updateInspections(this.device_id, newInspections);
    request.subscribe(
      (response) => {
        // console.log('HTTP Response: ' + response + ' | ' + JSON.stringify(response));
      },
      (error) => {
        // console.log('HTTP Error: ' + error + ' | ' + JSON.stringify(error));
      },
      () => {
        // console.log('HTTP PUT Inspection Completed.');
        this.retrieveInspections();
     });
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  stringify(str: string) {
    return JSON.stringify(str);
  }
}
