import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NewAuthService } from '../services/oauth.service';

@Injectable()
export class TokenAuthInterceptor implements HttpInterceptor {
  constructor(private authService: NewAuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If a token exists in the authService, upgrade ANY http call to use the token in the requests
    if (this.authService && this.authService.hasToken()) {
      // console.log('Intercepting HTTP request, adding token!');
      // console.log('Old request:'  + JSON.stringify(request));
      const token = this.authService.getToken();
      // console.log("Token found: " + token);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
      }
      });
      // console.log('New request: ' + JSON.stringify(request));
    }
    return next.handle(request);
  }
}
