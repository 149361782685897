import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { Device, Devices, Compactors } from 'src/app/models/device';
import { DeviceAPIService } from 'src/app/services/device-api.service';
import { LanguageService } from 'src/app/services/language.service';
import { DeviceStatusFlag } from 'src/app/models/device';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  templateUrl: './dashboardview.component.html',
  styleUrls: ['./dashboardview.component.scss']
})
export class DashboardViewComponent implements OnInit, OnDestroy {
  public devices$: Observable<Compactors>;
  public devices: Compactors;

  public filter: string = 'name';
  public onlineFilter: string = '';

  public subtitle: string = null;
  private subscriptions: Subscription[] = [];
  public showAmounts: string[];

  public deviceSorting: string = 'fill';
  public deviceShowMax = 'showAll';
  public deviceOrder = 'DESC';

  public filterType: string = 'name';
  public filterText: string = '';

  constructor(private deviceService: DeviceAPIService,
              private router: Router,
              private routeSnapshot: ActivatedRoute,
              private languageService: LanguageService
              ) {
    this.devices$ = this.deviceService.getAll(); // Load data early into Observable/BehaviorSubject
    this.showAmounts = ['5', '10', '25', '50']; // all gets added in the HTML
    this.retrieveSorting();
  }

  ngOnInit() {
     // Subscribe and assign data
    this.subscriptions.push(this.devices$.subscribe(d => this.devices = d));
    // Monitor route for changes in url parameters (used for filtering the dataset)
    // this.subscriptions.push(this.routeSnapshot.params.subscribe(params => this.parametersChanged(params)));
  }

  ngOnDestroy() {
    // Unsubscribe all subscriptions, to prevent continuous creation of new subscriptions as navigation changes between views
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.length = 0;
  }

  /*
  private parametersChanged(params: { [key: string]: any }): void {
    const onlineStr = this.languageService.getTranslation(_('gauge.online'));
    const offlineStr = this.languageService.getTranslation(_('gauge.offline'));
    const emergencyStr = this.languageService.getTranslation(_('gauge.emergency'));
    switch (params.filter) {
      case 'online':    this.subtitle = onlineStr; break;
      case 'offline':   this.subtitle = offlineStr; break;
      case 'emergency': this.subtitle = emergencyStr; break;
      default:          this.subtitle = null;
    }
    this.filter = params.filter;
  }*/

  private retrieveSorting() {
    const storedSorting = localStorage.getItem('sorting');
    if (storedSorting != null) {
      this.deviceSorting = storedSorting;
    }
    const storedOrder = localStorage.getItem('sortingorder');
    if (storedOrder != null) {
      this.deviceOrder = storedOrder;
    }
    const storedFilter = localStorage.getItem('filter');
    if (storedFilter != null) {
      this.filter = storedFilter;
    }
    const storedFilterText = localStorage.getItem('filtertext');
    if (storedFilterText != null) {
      this.filterText = storedFilterText;
    }
  }

  public updateSorting() {
    localStorage.setItem('sorting', this.deviceSorting);
    localStorage.setItem('sortingorder', this.deviceOrder);
    localStorage.setItem('filter', this.filter);
    localStorage.setItem('filtertext', this.filterText);
  }

  // Methods for retrieving the number of devices
  public totalDevices() {
    return (this.devices && this.devices.compactors ? this.devices.compactors.length : 0);
  }

  public offlineDevices() {
    return this.devicesCount(DeviceStatusFlag.OFFLINE);
  }

  public emergencyDevices() {
    return this.devicesCount(DeviceStatusFlag.EMERGENCY);
  }

  public onlineDevices() {
    return this.totalDevices() - this.offlineDevices();
  }

  private devicesCount(filterFlag: DeviceStatusFlag) {
    const result = (this.devices && this.devices.compactors ? this.devices.compactors.filter(d => (d.status_flags & filterFlag)) : null);
    return result ? result.length : 0;
  }

  public refreshData(): void {
    this.devices$ = this.deviceService.getAll();
  }

  public showDetails(device: Device): void {
    // Navigate to a detail page of a device
    this.router.navigate(['device', device.id]);
  }
}
