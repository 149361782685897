import { Pipe, PipeTransform } from '@angular/core';
import { CompactorEvent, CompactorEvents } from '../../models/device';

// Sorter Pipe for the Events Table in de Device View
@Pipe({
  name: 'eventSorter'
})
export class EventSorterPipe implements PipeTransform {
  transform(events: CompactorEvent[], orderStr = '', sorting: string): CompactorEvent[] {
    // console.log("Sorting events. orderStr = " + orderStr + " - sorting = " + sorting);
    // console.log(JSON.stringify("Events: " + events));
    if (!events) return;
    const order: number = orderStr === 'ASC' ? -1 : 1;
    switch (sorting) {
      case 'date': default:
        return events.sort((a, b) => {
          if (a.timestamp > b.timestamp) return -order;
          if (a.timestamp < b.timestamp) return order;
          return 0;
        });
      case 'event':
        return events.sort((a, b) => {
          if (a.event > b.event) return -order;
          if (a.event < b.event) return order;
          return 0;
        });
      }
    }
}
