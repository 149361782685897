import { Pipe, PipeTransform } from '@angular/core';
import { Devices, Device, DeviceStatusFlag } from '../../models/device';

@Pipe({
  name: 'deviceSorter'
})
export class DeviceSorterPipe implements PipeTransform {
  transform(devices: Devices, orderStr = '', sorting: string): Devices {
    if (!devices) return;
    const order: number = orderStr === 'ASC' ? -1 : 1;
    switch (sorting) {
      case 'fill':
        return devices.sort((a, b) => {
          if (a.percentage > b.percentage) return -order;
          if (a.percentage < b.percentage) return order;
          return 0;
        });
      case 'name':
        return devices.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return -order;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return order;
          return 0;
        });
      case 'location':
        return devices.sort((a, b) => {
          if (a.address && b.address) {
            if (a.address.city.toLowerCase() > b.address.city.toLowerCase()) return -order;
            if (a.address.city.toLowerCase() < b.address.city.toLowerCase()) return order;
            return 0;
          } else {
            return 0;
          }
        });
      case 'lastupdate':
        return devices.sort((a, b) => {
          if (a.timestamp > b.timestamp) return -order;
          if (a.timestamp < b.timestamp) return order;
          return 0;
        });
      case 'NEN':
        return devices.sort((a, b) => {
          if (this.getServiceDate(a, 'NEN') > this.getServiceDate(b, 'NEN')) return -order;
          if (this.getServiceDate(a, 'NEN') < this.getServiceDate(b, 'NEN')) return order;
          return 0;
        });
      case 'maintenance':
        return devices.sort((a, b) => {
          if (this.getServiceDate(a, 'Maintenance') > this.getServiceDate(b, 'Maintenance')) return -order;
          if (this.getServiceDate(a, 'Maintenance') < this.getServiceDate(b, 'Maintenance')) return order;
          return 0;
       });
      case 'serialnumber':
        return devices.sort((a, b) => {
          if (a.metadata && a.metadata.serial_number && b.metadata && b.metadata.serial_number) {
            if (a.metadata.serial_number > b.metadata.serial_number) return -order;
            if (a.metadata.serial_number < b.metadata.serial_number) return order;
          }
          return 0;
        });
      case 'fraction':
        return devices.sort((a, b) => {
          if (a.fraction && b.fraction) {
            if (a.fraction.name.toLowerCase() > b.fraction.name.toLowerCase()) return -order;
            if (a.fraction.name.toLowerCase() < b.fraction.name.toLowerCase()) return order;
          } else {
            if (a.fraction && !b.fraction) return -order;
            if (b.fraction && !a.fraction) return order;
          }
          return 0;
        });
      case 'offline':
        return devices.sort((a, b) => {
          if ((a.status_flags & DeviceStatusFlag.OFFLINE) > (b.status_flags & DeviceStatusFlag.OFFLINE)) return -order;
          if ((a.status_flags & DeviceStatusFlag.OFFLINE) < (b.status_flags & DeviceStatusFlag.OFFLINE)) return order;
          return 0;
        });
      case 'status':
          return devices.sort((a, b) => {
            if ((a.status_flags & DeviceStatusFlag.OFFLINE) > (b.status_flags & DeviceStatusFlag.OFFLINE)) return -order;
            if ((a.status_flags & DeviceStatusFlag.OFFLINE) < (b.status_flags & DeviceStatusFlag.OFFLINE)) return order;
            return 0;
          });
      case 'emergencyStop':
        return devices.sort((a, b) => {
          if ((a.status_flags & DeviceStatusFlag.EMERGENCY) > (b.status_flags & DeviceStatusFlag.EMERGENCY)) return -order;
          if ((a.status_flags & DeviceStatusFlag.EMERGENCY) < (b.status_flags & DeviceStatusFlag.EMERGENCY)) return order;
          return 0;
        });
      case 'unplugged':
        return devices.sort((a, b) => {
          if ((a.status_flags & DeviceStatusFlag.UNPLUGGED) > (b.status_flags & DeviceStatusFlag.UNPLUGGED)) return -order;
          if ((a.status_flags & DeviceStatusFlag.UNPLUGGED) < (b.status_flags & DeviceStatusFlag.UNPLUGGED)) return order;
          return 0;
        });
      case 'bluelight':
        return devices.sort((a, b) => {
          if ((a.status_flags & DeviceStatusFlag.BLUE_LIGHT) > (b.status_flags & DeviceStatusFlag.BLUE_LIGHT)) return -order;
          if ((a.status_flags & DeviceStatusFlag.BLUE_LIGHT) < (b.status_flags & DeviceStatusFlag.BLUE_LIGHT)) return order;
          return 0;
        });
      }
    return devices;
  }

  private getServiceDate(device: Device, type: string) {
    if (device && device.service) {
      for (const service of device.service) {
        if (service.type === type) {
          const date = service.timestamp_next;
          return date;
        }
      }
    }
    return null;
  }
}
