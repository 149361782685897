import { Pipe, PipeTransform } from '@angular/core';
import { CompactorInspection } from '../../models/device';

// Sorter Pipe for the Service/Inspections Table in de Service Page
@Pipe({
  name: 'serviceSorter'
})
export class ServiceSorterPipe implements PipeTransform {
  transform(service: CompactorInspection[], orderStr = '', sorting: string): CompactorInspection[] {
    if (!service) return;
    const order: number = orderStr === 'ASC' ? -1 : 1;
    switch (sorting) {
      case 'timestamp_last': default:
        return service.sort((a, b) => {
          if (a.timestamp_last > b.timestamp_last) return -order;
          if (a.timestamp_last < b.timestamp_last) return order;
          return 0;
        });
      case 'timestamp_next':
        return service.sort((a, b) => {
          if (a.timestamp_next > b.timestamp_next) return -order;
          if (a.timestamp_next < b.timestamp_next) return order;
          return 0;
        });
      case 'type':
        return service.sort((a, b) => {
          if (a.type > b.type) return -order;
          if (a.type < b.type) return order;
          return 0;
        });
    }
  }
}
