import { Injectable } from '@angular/core';
import { CompactorEvent } from 'src/app/models/device';
import { LanguageService } from 'src/app/services/language.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

// This service is responsible for some basic utility methods
@Injectable()
export class UtilsService {

  constructor(private languageService: LanguageService) {}

  // Some Font Awesome icons to be used throughtout the frontend
  public TRIANGLE = 'fa fa-exclamation-triangle';
  public TRASH = 'fa fa-trash';
  public CIRCLE = 'fa fa-dot-circle-o';
  public BOLT = 'fa fa-bolt';
  public PLUG = 'fa fa-plug';
  public HAND = 'fa fa-hand-paper-o';
  public CHECKMARK = 'fa fa-check-circle';
  public LINK = 'fa fa-link';
  public LINK_BROKEN = 'fa fa-chain-broken';
  public WRENCH = 'fa fa-wrench';
  public POWER = 'fa fa-power-off';
  public RSS = 'fa fa-rss';

  // Returns a UNIX Timestamp for the given period (selected by the user)
  // Returns null if no valid Timestamp could be created (default period or invalid period)
  public getTimestamp(period: string): string {
    const today = new Date();
    let date: Date = null;
    switch (period) {
      case 'DEFAULT': default: date = null; break;
      case 'DAY': date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1); break;
      case 'WEEK': date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7); break;
      case 'MONTH': date = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()); break;
      case 'MONTHS-3': date = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()); break;
      case 'MONTHS-6': date = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()); break;
      case 'YEAR': date = new Date(today.getFullYear(), today.getMonth() - 12, today.getDate()); break;
    }
    const timestamp = date != null ? (date.getTime() / 1000).toString() : null;
    return timestamp;
  }

  // Return the corresponding icon (from Font Awesome) for the given Compactor Event
  public getEventIcon(compactorEvent: CompactorEvent) {
    return this.getEventIconbyName(compactorEvent.event);
  }

  // Return the corresponding icon (from Font Awesome) for the given Event Name
  public getEventIconbyName(eventName: string) {
    switch (eventName) {
      case 'EmergencyOff': return this.TRIANGLE;
      case 'EmergencyOn': return this.TRIANGLE;
      case 'ContainerGone': return this.LINK_BROKEN;
      case 'ContainerOn': return this.LINK;
      case 'ContainerFull': return this.TRASH;
      case 'AlmostFull': return this.TRASH;
      case 'AlmostFullIn': return this.TRASH;
      case 'AlmostFullInReset': return this.TRASH;
      case 'HitchOn': return this.WRENCH;
      case 'HitchOff': return this.WRENCH;
      case 'PowerOn': return this.POWER;
      case 'PowerOff': return this.POWER;
      case 'ManualOff': return this.CHECKMARK;
      case 'ManualOn': return this.HAND;
      case 'ManualRestart': return this.HAND;
      default: return '';
    }
  }

  // Convert a country type (f.e. 'NL', 'BE') to a translated String (f.e. 'Dutch')
  public translateCountry(country: string) {
    let translated = '';
    switch (country) {
      case 'NL': translated = this.languageService.getTranslation(_('countries.nl')); break;
      case 'BE': translated = this.languageService.getTranslation(_('countries.be')); break;
    }
    return translated;
  }

  // Convert a fraction type (f.e. 'Paper', 'Rest') to a translated String (f.e. 'Papier', 'Restafval')
  public translateFraction(fraction: string) {
    let translated = '';
    switch (fraction) {
      default: translated = fraction; break;
      case 'Unknown': translated = this.languageService.getTranslation(_('fraction.unknown')); break;
      case 'Paper': translated = this.languageService.getTranslation(_('fraction.paper')); break;
      case 'Glass': translated = this.languageService.getTranslation(_('fraction.glass')); break;
      case 'Rest': translated = this.languageService.getTranslation(_('fraction.rest')); break;
      case 'Foil': translated = this.languageService.getTranslation(_('fraction.foil')); break;
      case 'Plastic': translated = this.languageService.getTranslation(_('fraction.plastic')); break;
    }
    return translated;
  }
}
