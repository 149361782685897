import { Pipe, PipeTransform } from '@angular/core';
import { CompactorSession } from '../../models/device';

// Sorter Pipe for the Session Table in de Device View
@Pipe({
  name: 'sessionSorter'
})
export class SessionSorterPipe implements PipeTransform {
  transform(sessions: CompactorSession[], orderStr = '', sorting: string): CompactorSession[] {
    if (!sessions) return;
    const order: number = orderStr === 'ASC' ? -1 : 1;
    switch (sorting) {
      case 'session':
        return sessions.sort((a, b) => {
          if (a.id > b.id) return -order;
          if (a.id < b.id) return order;
          return 0;
        });
      case 'createdAt': default:
        return sessions.sort((a, b) => {
          if (a.created_at > b.created_at) return -order;
          if (a.created_at < b.created_at) return order;
          return 0;
        });
      case 'completedAt':
        return sessions.sort((a, b) => {
          if (a.completed_at > b.completed_at) return -order;
          if (a.completed_at < b.completed_at) return order;
          return 0;
        });
      case 'cycles':
        return sessions.sort((a, b) => {
          if (a.cycles > b.cycles) return -order;
          if (a.cycles < b.cycles) return order;
          return 0;
        });
      case 'percentage':
        return sessions.sort((a, b) => {
          if (a.percentage > b.percentage) return -order;
          if (a.percentage < b.percentage) return order;
          return 0;
        });
      case 'massEstimate':
        return sessions.sort((a, b) => {
          if (a.mass_estimate > b.mass_estimate) return -order;
          if (a.mass_estimate < b.mass_estimate) return order;
          return 0;
        });
      case 'massCorrected':
        return sessions.sort((a, b) => {
          if (a.mass_corrected > b.mass_corrected) return -order;
          if (a.mass_corrected < b.mass_corrected) return order;
          return 0;
        });
      }
  }
}
