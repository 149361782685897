import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from 'src/environments/environment';
import { DeviceAPIService } from 'src/app/services/device-api.service';
import { UserProfile } from 'src/app/models/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewAuthService {
  private token: string = null;
  private loggedIn: boolean = false;
  private sourceUrl: string = null;
  public profile$: Observable<UserProfile>;
  private profile: UserProfile;

  constructor(private oauthService: OAuthService,
              private deviceService: DeviceAPIService) {
    this.configureWithNewConfigApi();
  }

  // Configure OAuth Settings (issuer/scope/clientid), uses authConfig in this document
  private configureWithNewConfigApi() {
    this.oauthService.configure(authConfig); // located in environment.ts
    // this.oauthService.tokenValidationHandler = new JwksValidationHandler(); // probably not needed in Auth Code Flow

    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(_ => {
      // console.log('Logged in to the Auth Service.');
    }).catch(err => {
      console.log('Unable to login to the Auth Service.');
      if (err != null) {
        console.error('Error: ' + err);
        console.error('Error: ' + JSON.stringify(err));
      }
      this.loggedIn = false;
    });
    // Call this.oauthService.tryLogin() if discovery document is not used.
  }

  // ==== LOGGING IN ====

  // This method is called from the auth-callback when there we no errors
  public loginSuccess() {
    // console.log('[OAUTH Service] We are logged in now.');
    const claims = this.oauthService.getIdentityClaims();
    const accessToken = this.oauthService.getAccessToken();
    // console.log('[OAUTH Service] Access token: ' + accessToken);
    // console.log('[OAUTH Service] Claims: ' + JSON.stringify(claims));
    this.loggedIn = true;
    this.token = accessToken;

    const expiry_date = this.oauthService.getAccessTokenExpiration();
    // console.log('Expiry date: ' + expiry_date);
    const now = Math.round(new Date().getTime());
    // console.log('Current time: ' + now);
    // Communicated timestamps are unix epoch format, Javascript's timestamps are in milliseconds, so we need to convert

    if (expiry_date < now) {
      // Token expired, remove the token
      // console.log('Access token expired!');
      this.loggedIn = false;
    } else {
      // console.log('Access token valid.');
      localStorage.setItem('token', this.token); // store the Token in the localStorage here
      localStorage.setItem('expire', String(expiry_date));
      this.retrieveProfile();
    }
  }

  private retrieveProfile() {
    this.profile$ = this.deviceService.getProfile();
    this.profile$.subscribe((profile: UserProfile) => {
      this.profile = profile;
      // console.log('[OAUTH] Profile found: ' + JSON.stringify(profile));
    });
  }

  public login() {
    // console.log('[OAUTH Service] Logging in.');
    this.oauthService.initLoginFlow(); // Authorization Code Flow
  }

  // No access token was present so we check if one was saved in the local storage
  public loginThroughStorage() {
    const newToken = localStorage.getItem('token');
    if (newToken != null) {
      // console.log('Retrieved token. We are still logged in.');
      const expiry_date = Number(localStorage.getItem('expire'));
      // Check if stored token is still valid / expired
      if (expiry_date != null) {
        // console.log('Checking expiry date: ' + expiry_date);
        const now = Math.round(new Date().getTime());
        // console.log('Current time: ' + now);
        if (expiry_date >= now) {
          // console.log('Token is valid!');
          this.token = newToken;
          this.loggedIn = true;
          this.retrieveProfile();
          return;
        } else {
          // console.log('Token is expired. Not logged in.');
        }
      }
    }
    localStorage.removeItem('token');
    localStorage.removeItem('expire');
  }

  // ==== LOGGING OUT ====

  public logout() {
    // console.log('Logging out.');
    // console.log('Logout URL: ' + this.oauthService.logoutUrl);
    if (this.loggedIn === false || this.token == null) {
      console.log('Token is null, not logging out.');
    } else {
      // this.oauthService.revokeTokenAndLogout(); // Not sure if we want to include this
      this.oauthService.logOut();
      this.loggedIn = false;
      localStorage.removeItem('token');
      localStorage.removeItem('expire');
    }
  }

  // ==== RETRIEVE INFORMATION/DATA/OID STUFF ====

  // Returns true when we are a Compactor/Octopus Manager
  public isManager() {
    return this.profile && this.profile.role === 72;
  }

  public getProfile() {
    return this.profile;
  }

  public isLoggedin() {
    return this.loggedIn;
  }

  public getUsername(): string {
    return (this.profile != null && this.profile.name != null ? this.profile.name : 'Unknown');
  }

  public getAuth(): OAuthService {
    return this.oauthService;
  }

  public hasToken(): boolean {
    return !!(this.token);
  }

  public getToken(): string {
    return this.token;
  }

  public setSourceUrl(url: string): void {
    this.sourceUrl = url;
  }

  public getSourceUrl(): string {
    const url = unescape(this.sourceUrl);
    this.sourceUrl = null;
    return url;
  }
}
