import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from 'src/environments/environment';

@Injectable()
export class LanguageService {

  private defaultLanguage: string = environment.defaultLanguage;

  constructor(private translate: TranslateService) {
    let lang = localStorage.getItem('language');
    // console.log('Language found in storage: ' + lang);
    if (lang == null) {
      lang = this.defaultLanguage; // default language from environment config
      // console.log('Default lang found: ' + lang);
    }
    // console.log('Setting language: ' + lang);
    translate.setDefaultLang(lang);
    this.useLanguage(lang);
    const currentLang = this.translate.currentLang; // get current language
    // console.log('[constructor] Current Language: ' + currentLang);
  }

  // Used for on-the-fly language switching
  useLanguage(language: string) {
    this.translate.use(language);
    // console.log('Changing language to ' + language);
    localStorage.setItem('language', language);
  }

  // Returns the translation for the given tag, from the JSON files, using the current language
  getTranslation(str: string) {
    const currentLang = this.translate.currentLang; // get current language
    // console.log("Current Language: " + currentLang);
    let translation = this.translate.instant(str);
    // console.log("OLD Translation: " + translation);
    if (translation === null || translation === '') {
      this.useLanguage('en');
      translation = this.translate.instant(str);
      // console.log("NEW Translation: " + translation);
      this.useLanguage(currentLang);
    }
    return translation;
  }

  // Returns the currently active language
  getLanguage() {
    let currentLang = this.translate.currentLang; // get current language
    if (!currentLang || currentLang === '') {
        currentLang = this.translate.getDefaultLang();
    }
    // console.log("Current Language: " + currentLang);
    return currentLang;
  }
}
