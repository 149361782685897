import { Component } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';

// Component for manual measurements for a specific device
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public logo: string = environment.derooijlogo;

  constructor(private languageService: LanguageService) {}

  // Used for on-the-fly language switching
  useLanguage(language: string) {
    return this.languageService.useLanguage(language);
  }

  getLanguage() {
    return this.languageService.getLanguage();
  }
}
