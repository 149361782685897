import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompactorFills, CompactorEvents, CompactorSessions, CompactorSettings } from '../models/device';
import { Compactor, Compactors, Address, CompactorLog, CompactorLogs } from '../models/device';
import { CompactorAlarms, CompactorAlarm, CompactorMeasurement } from '../models/device';
import { CompactorInspections, CompactorInspection} from '../models/device';
import { CompactorAlarmTemplates } from '../models/device';
import { SingleAlarmAction, SingleCompactorLog } from 'src/app/models/device';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { share } from 'rxjs/operators';
import { UserProfile } from '../models/user';

const ONLY_TEST_COMPACTOR = false;

@Injectable()
export class DeviceAPIService {

  constructor(private http: HttpClient) {}

  // Can be used while testing new functionality, returns true when ID is that of the TestCompactor
  private isTestCompactor(compactorId: string) {
    return compactorId === 'boopmsr9ehkil187bpgg';
  }

  // Abstract get() to be reused throughtout the Service
  public get<T>(endpoint: string): Observable<T> {
    const url = `${environment.apiBasePath}/${endpoint}`;
    // console.log("Fetching: " + url);
    // share() prevents multiple API requests among Subscribers to this Observable
    const result = this.http.get<any>(url).pipe(share());
    return result;
  }

  // ==== GET METHODS ====

  public getAll(): Observable<Compactors> {
    return this.get<Compactors>(`compactors`);
  }

  // Get All Compactors with Settings Query enabled
  public getAllWithSettings(): Observable<Compactors> {
    return this.get<Compactors>(`compactors?settings=true&details=true`);
  }

  public getOne(id: string): Observable<Compactor> {
    return this.get<Compactor>(`compactors/${id}`);
  }

  public getAlarms(id: string): Observable<CompactorAlarms> {
    return this.get<CompactorAlarms>(`compactors/${id}/alarms`);
  }

  public getAlarm(id: string): Observable<CompactorAlarm> {
    return this.get<CompactorAlarm>(`compactors/${id}/alarms`);
  }

  public getTemplates(): Observable<CompactorAlarmTemplates> {
    return this.get<CompactorAlarmTemplates>(`alarms/templates/compactor`);
  }

  // ==== PROFILE INFORMATION ====

  // API REQUEST: /profile
  public getProfile(): Observable<UserProfile> {
    return this.get<UserProfile>(`profile`);
  }

  // ==== EVENTS ====

  // API REQUEST: /events
  public getEvents(id: string): Observable<CompactorEvents> {
    return this.get<CompactorEvents>(`compactors/${id}/events`);
  }

  // API REQUEST: /events?start_at=timestamp
  public getEventsExtra(id: string, timestamp: string, limit?: number): Observable<CompactorEvents> {
    let arg = timestamp !== '' ? ('?start_at=' + timestamp) : '';
    if (limit) arg += (arg === '' ? '?' : '&') + 'limit=' + limit;
    // console.log('GET /events' + arg);
    return this.get<CompactorEvents>(`compactors/${id}/events` + arg);
  }

  // ==== SESSIONS ====

  // API REQUEST: /sessions
  public getSessions(id: string): Observable<CompactorSessions> {
    return this.get<CompactorSessions>(`compactors/${id}/sessions`);
  }

  // API REQUEST: /sessions?start_at=timestamp
  public getSessionsExtra(id: string, timestamp: string, limit?: number): Observable<CompactorSessions> {
    let arg = timestamp !== '' ? ('?start_at=' + timestamp) : '';
    if (limit) arg += (arg === '' ? '?' : '&') + 'limit=' + limit;
    // console.log('GET /sessions' + arg);
    return this.get<CompactorSessions>(`compactors/${id}/sessions` + arg);
  }

  // ==== FILLS ====

  // API REQUEST: /fills
  public getFills(id: string): Observable<CompactorFills> {
    return this.get<CompactorFills>(`compactors/${id}/fills`);
  }

  // API REQUEST: /fills?start_at=timestamp&sanitize=true/false&limit=x
  public getFillsExtra(id: string, timestamp: string, sanitized: boolean, limit?: number): Observable<CompactorFills> {
    let arg = timestamp !== null ? ('?start_at=' + timestamp) : '';
    arg += (arg === '' ? '?' : '&') + 'sanitize=' + (sanitized ? 'true' : 'false');
    // if (limit) arg += (arg === '' ? '?' : '&') + 'limit=' + limit;
    // console.log('GET /fills' + arg);
    return this.get<CompactorFills>(`compactors/${id}/fills` + arg);
  }

  // API REQUEST: /fills?start_at=timestamp&extensive=true
  public getExtensiveFills(id: string, timestamp?: string) {
    const arg = timestamp !== null ? ('?start_at=' + timestamp) : '';
    // console.log('GET /fills' + arg);
    return this.get<CompactorFills>(`compactors/${id}/fills` + arg + `&extensive=true`);
  }

  // API REQUEST: /fills?sanitize=true/false
  public getSanizatedFills(id: string): Observable<CompactorFills> {
    return this.get<CompactorFills>(`compactors/${id}/fills?sanitize=true`);
  }

   // API REQUEST: /fills?limit=x
   public getFillsLimit(id: string, limit: number): Observable<CompactorFills> {
    return this.get<CompactorFills>(`compactors/${id}/fills?limit=` + limit);
  }

  // ==== LOG ====

  // API REQUEST: /log
  public getLog(id: string): Observable<CompactorLogs> {
    return this.get<CompactorLogs>(`compactors/${id}/log`);
  }

  // API REQUEST: /log
  public getLogItemsFrom(id: string, timestamp: string): Observable<CompactorLogs> {
    return this.get<CompactorLogs>(`compactors/${id}/log?start_at=` + timestamp);
  }

  // ==== INSPECTIONS ====

  // API REQUEST: /inspection
  public getInspections(id: string): Observable<CompactorInspections> {
    return this.get<CompactorInspections>(`compactors/${id}/inspection`);
  }

  // Updating Compactor name using PUT
  public updateInspections(compactorId: string, newInspections: CompactorInspections): Observable<object> {
    if (!ONLY_TEST_COMPACTOR || this.isTestCompactor(compactorId)) {
      const endpoint = `compactors/${compactorId}/inspection`;
      const url = `${environment.apiBasePath}/${endpoint}`;
      const request = this.http.put(url, newInspections).pipe(share());
      return request;
    }
  }

  // ==== PUBLIC POST METHODS ====

  // Posting a new Log entry to /compactors/{id}/log
  public postNewLog(compactorId: string, newLog: SingleCompactorLog): Observable<object> {
    if (!ONLY_TEST_COMPACTOR || this.isTestCompactor(compactorId)) {
      const endpoint = `compactors/${compactorId}/log`;
      const url = `${environment.apiBasePath}/${endpoint}`;
      const request = this.http.post(url, newLog).pipe(share());
      return request;
    }
  }

  // Posting a new Manual Measurement through a HTTP POST request
  public postNewMeasurement(compactorId: string, measurement: CompactorMeasurement): Observable<object> {
    if (!ONLY_TEST_COMPACTOR || this.isTestCompactor(compactorId)) {
      const endpoint = `compactors/${compactorId}/measurement`;
      const url = `${environment.apiBasePath}/${endpoint}`;
      const request = this.http.post(url, measurement).pipe(share());
      return request;
    }
  }

  // Post a new Alarm on the given Compactor using POST /compactors/id/alarms
  public postNewAlarm(compactorId: string, newAlarm: SingleAlarmAction): Observable<object> {
    if (!ONLY_TEST_COMPACTOR || this.isTestCompactor(compactorId)) {
      const endpoint = `compactors/${compactorId}/alarms`;
      const url = `${environment.apiBasePath}/${endpoint}`;
      const request = this.http.post(url, newAlarm).pipe(share());
      return request;
    }
  }

  // Posting a request to restart the current Compactor Session
  public restartCompactorSession(compactorId: string, randomString: string): Observable<object> {
    if (!ONLY_TEST_COMPACTOR || this.isTestCompactor(compactorId)) {
      const restartSessionData = {
        correlation: randomString
      };
      // console.log('Sending object: ' + JSON.stringify(restartSessionData));
      const endpoint = `compactors/${compactorId}/restart_session`;
      const url = `${environment.apiBasePath}/${endpoint}`;
      const request = this.http.post(url, restartSessionData).pipe(share());
      return request;
    }
  }

  // ==== PUBLIC PUT METHODS ====

   // Updating a Log entry to /compactors/{id}/log
   public updateLog(compactorId: string, newLog: SingleCompactorLog, logId: number): Observable<object> {
    if (!ONLY_TEST_COMPACTOR || this.isTestCompactor(compactorId)) {
      const endpoint = `compactors/${compactorId}/log`;
      const url = `${environment.apiBasePath}/${endpoint}/${logId}`;
      const request = this.http.put(url, newLog).pipe(share());
      return request;
    }
  }

  // Updating an Alarm on the given Compactor using PUT
  public updateAlarm(compactorId: string, newAlarm: SingleAlarmAction, alarmId: number): Observable<object> {
    if (!ONLY_TEST_COMPACTOR || this.isTestCompactor(compactorId)) {
      const tempObject = {action: newAlarm.action};
      const endpoint = `compactors/${compactorId}/alarms`;
      const url = `${environment.apiBasePath}/${endpoint}/${alarmId}`;
      const request = this.http.put(url, tempObject).pipe(share());
      return request;
    }
  }

  // Updating Compactor Settings using PUT
  public updateCompactorSettings(compactorId: string, newSettings: CompactorSettings): Observable<object> {
    if (!ONLY_TEST_COMPACTOR || this.isTestCompactor(compactorId)) {
      const tempObject = {settings: newSettings};
      const endpoint = `compactors/${compactorId}/settings`;
      const url = `${environment.apiBasePath}/${endpoint}`;
      const request = this.http.put(url, tempObject).pipe(share());
      return request;
    }
  }

  // Updating Compactor Address using PUT
  public updateCompactorAddress(compactorId: string, newAddress: Address): Observable<object> {
    if (!ONLY_TEST_COMPACTOR || this.isTestCompactor(compactorId)) {
      const tempObject = {address: newAddress};
      const endpoint = `compactors/${compactorId}/address`;
      const url = `${environment.apiBasePath}/${endpoint}`;
      const request = this.http.put(url, tempObject).pipe(share());
      return request;
    }
  }

  // Updating Compactor name using PUT
  public updateCompactorName(compactorId: string, newName: string): Observable<object> {
    if (!ONLY_TEST_COMPACTOR || this.isTestCompactor(compactorId)) {
      const tempObject = {name: newName};
      const endpoint = `compactors/${compactorId}/name`;
      const url = `${environment.apiBasePath}/${endpoint}`;
      const request = this.http.put(url, tempObject).pipe(share());
      return request;
    }
  }

  // ==== PUBLIC DELETE METHODS ====

  // Tries to delete the Alarm with the given Id on the given Compactor
  public deleteAlarm(compactorId: string, alarmId: string): Observable<object> {
    if (!ONLY_TEST_COMPACTOR || this.isTestCompactor(compactorId)) {
      const endpoint = `compactors/${compactorId}/alarms`;
      const url = `${environment.apiBasePath}/${endpoint}/${alarmId}`;
      const request = this.http.delete(url).pipe(share());
      return request;
    }
  }

  // Updating a Log entry to /compactors/{id}/log
  public deleteLog(compactorId: string, logId: number): Observable<object> {
    if (!ONLY_TEST_COMPACTOR || this.isTestCompactor(compactorId)) {
      const endpoint = `compactors/${compactorId}/log`;
      const url = `${environment.apiBasePath}/${endpoint}/${logId}`;
      const request = this.http.delete(url).pipe(share());
      return request;
    }
  }
}
