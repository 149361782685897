import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceAPIService } from 'src/app/services/device-api.service';
import { LanguageService } from 'src/app/services/language.service';
import { Compactor, CompactorSettings, Fraction, Address } from 'src/app/models/device';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Validators } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { NewAuthService } from 'src/app/services/oauth.service';

interface SessionRestart {
  [id: string]: number;
}

@Component({
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.scss']
})
export class DeviceSettingsViewComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  public device$: Observable<Compactor>;
  private device: Compactor;
  public settingsForm: FormGroup;
  public locationForm: FormGroup;
  public submitResponseLocation: string;
  public submitResponseSettings: string;
  public submitSessionRestartResponse: string;
  private currentThreshold: number;
  public countries = ['NL', 'BE'];
  public fractions = ['Unknown', 'Rest', 'Paper', 'Glass', 'Foil', 'Plastic'];


  constructor(private deviceService: DeviceAPIService,
              private languageService: LanguageService,
              private utilsService: UtilsService,
              private authService: NewAuthService,
              private activatedRoute: ActivatedRoute,
              private formBuilder: FormBuilder) {
    this.loadData();
    this.resetForms();
  }

  ngOnInit() {
    this.device$.subscribe((device: Compactor) => {
      this.device = device;
      this.checkRestart();
      if (device.compactor.settings) {
        this.currentThreshold = device.compactor.settings.almostfull_threshold;
      }
      this.resetForms();
    });
  }

  ngOnDestroy() {
    // Unsubscribe all subscriptions, to prevent continuous creation of new subscriptions as navigation changes between views
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.length = 0;
  }

  private resetForms() {
    this.resetLocationForm();
    this.resetSettingsForm();
  }

  private loadData() {
    this.device$ = this.deviceService.getOne(this.activatedRoute.snapshot.params.id);
  }

  // Check if we have recently (last 5 minutes) used the Restart Session button and disable if that's the case
  private checkRestart() {
    this.submitSessionRestartResponse = null;
    const sessionRestartsString = localStorage.getItem('sessionRestarts');
    const id = this.device && this.device.compactor ? this.device.compactor.id : null;
    if (sessionRestartsString === null || id === null) {
      return;
    }
    const sessionRestarts: SessionRestart = JSON.parse(sessionRestartsString);
    // console.log("Stored Session Restarts: " + JSON.stringify(sessionRestarts));
    const timestampRestart = sessionRestarts[id];
    if (timestampRestart) {
      const currentDate: Date = new Date();
      const timestamp = currentDate.getTime();
      const delaySeconds = 5 * (60 * 1000);
      const hourAdded = new Date(timestamp - delaySeconds).getTime();
      // console.log("Current Timestamp - 5 minutes: " + hourAdded);
      if (timestampRestart > hourAdded) {
        // console.log("Stored time is less than the delay ago. Disabling button.");
        this.submitSessionRestartResponse = this.languageService.getTranslation(_('settings.sessionRecentlyRestarted'));
      } else {
        // console.log("Stored time is longer than the specified delay ago. Enabling button.");
        delete sessionRestarts[id];
        localStorage.setItem('sessionRestarts', JSON.stringify(sessionRestarts));
      }
    }
  }

  // Reset form
  public resetLocationForm() {
    if (this.device && this.device.compactor.address) {
      this.locationForm = this.formBuilder.group({
        company: [this.device.compactor.address.company ? this.device.compactor.address.company : ''],
        address: [this.device.compactor.address.street],
        additional: [this.device.compactor.address.additional ? this.device.compactor.address.additional : ''],
        postal_code: [this.device.compactor.address.postal_code],
        city: [this.device.compactor.address.city],
        country_code: [this.device.compactor.address.country_code],
        dock_number: [this.device.compactor.address.dock_number ? this.device.compactor.address.dock_number : '']
      });
    } else {
      this.locationForm = this.formBuilder.group({
        company: '',
        address: '',
        additional: '',
        postal_code: '',
        city: '',
        country_code: '',
        dock_number: ''
      });
    }
  }

  // Reset form
  public resetSettingsForm() {
    const deviceName: string = this.device && this.device.compactor ? this.device.compactor.name : '';
    const fraction: Fraction = this.device && this.device.compactor &&
      this.device.compactor.fraction ? this.device.compactor.fraction : null;
    // console.log("Fraction: " + JSON.stringify(fraction));
    this.settingsForm = this.formBuilder.group({
      name: [deviceName, Validators.required],
      threshold: [this.currentThreshold, [Validators.required, Validators.min(0), Validators.max(100)]],
      fraction_name: [fraction ? fraction.name : ''],
      fraction_type: [fraction ? fraction.type : 'unknown'],
    });
  }

  public translateCountry(country: string) {
    return this.utilsService.translateCountry(country);
  }

  public translateFraction(fraction: string) {
    return this.utilsService.translateFraction(fraction);
  }

  public isManager() {
    return this.authService && this.authService.isManager();
  }

  // ==== USER ACTIONS ====

  // Submitting form for changing settings on this Compactor
  // Threshold and name are updating using two diferent endpoints, and we only use them if data has changed
  public onSubmitSettings(settingsForm: FormGroup) {
    let newThreshold: number = Number(settingsForm.get('threshold').value);
    const newFraction: string = settingsForm.get('fraction_name') != null ? settingsForm.get('fraction_name').value : '';
    const newFractionType: string = settingsForm.get('fraction_type') != null ? settingsForm.get('fraction_type').value : '';

    if (isNaN(newThreshold) || newThreshold < 0 || newThreshold > 100) {
      // console.log("Threshold changed but invalid. Changing to old value.");
      newThreshold = this.currentThreshold;
    }
    this.updateSettings(newThreshold, newFraction, newFractionType);
    const deviceName = settingsForm.get('name').value;
    if (this.device != null && deviceName !== this.device.compactor.name) {
      // console.log('Device name changed. Updating');
      this.updateDeviceName(deviceName);
    } else {
      // console.log("Device name NOT changed. Not updating!");
    }
  }

  // Submitting form for changing settings on this Compactor
  // company, additional, dock_number are non-required fields
  // street, postal_code, city, country_code are required fields
  public onSubmitLocation(locationForm: FormGroup) {
    const company: string = locationForm.get('company') != null ? locationForm.get('company').value : '';
    const street: string = locationForm.get('address').value;
    const additional: string = locationForm.get('additional') != null ? locationForm.get('additional').value : '';
    const postal_code: string = locationForm.get('postal_code').value;
    const city: string = locationForm.get('city').value;
    const country_code: string = locationForm.get('country_code').value;
    const dock_number: number = Number(locationForm.get('dock_number').value);

    // TODO: Add validation for dock_number (must be a number)
    let newAddress: Address;
    if (dock_number != null) {
       newAddress = { company, street, additional, postal_code, city, country_code, dock_number };
    } else {
       newAddress = { company, street, additional, postal_code, city, country_code };
    }

    // console.log('New Address object: ' + JSON.stringify(newAddress));
    const id = this.device.compactor.id;
    const request: Observable<object> = this.deviceService.updateCompactorAddress(id, newAddress);
    request.subscribe(
      (response) => {
        // console.log('HTTP Response: ' + response + ' | ' + JSON.stringify(response));
      },
      (err) => {
        // console.log('HTTP Error: ' + err + ' | ' + JSON.stringify(err));
        this.submitResponseLocation = this.languageService.getTranslation(_('settings.submittedLocationFailed'));
      },
      () => {
        // console.log('HTTP Request completed.');
        this.submitResponseLocation = this.languageService.getTranslation(_('settings.submittedLocation'));
        this.loadData();
      }
    );
  }

  // Updating the Device threshold in the settings after the user changed it
  private updateSettings(newThreshold: number, newFraction: string, newFractionType: string) {
    const newSettings: CompactorSettings = {
      almostfull_threshold: newThreshold,
      fraction_name: newFraction,
      fraction_type: newFractionType
    };
    const id = this.device.compactor.id;
    // console.log('Settings object: ' + JSON.stringify(newSettings));
    const request: Observable<object> = this.deviceService.updateCompactorSettings(id, newSettings);
    this.submitResponseSettings = this.languageService.getTranslation(_('settings.submittedSettings'));
    request.subscribe(
      (response) => {
        // console.log('HTTP Response: ' + response + ' | ' + JSON.stringify(response));
      },
      (err) => {
        // console.log('HTTP Error: ' + err + ' | ' + JSON.stringify(err));
        this.submitResponseSettings = this.languageService.getTranslation(_('settings.submittedSettingsFailed'));
      },
      () => {
        // console.log('HTTP Request completed.');
        this.loadData();
      }
    );
  }

  // Updating the Device (Compactor) name after the user changed it
  private updateDeviceName(newDeviceName: string) {
    const id = this.device.compactor.id;
    const request: Observable<object> = this.deviceService.updateCompactorName(id, newDeviceName);
    request.subscribe(
      (response) => {
        // console.log('HTTP Response: ' + response + ' | ' + JSON.stringify(response));
      },
      (err) => {
        // console.log('HTTP Error: ' + err + ' | ' + JSON.stringify(err));
        this.submitResponseSettings = this.languageService.getTranslation(_('settings.submittedSettingsFailed'));
      },
      () => {
        // console.log('HTTP Request completed.');
      }
    );
  }

  // User clicked on Restart Session button
  public onSubmitRestartSession() {
    const id = this.device.compactor.id;
    // we generate a random string before the confirmation dialog is launched
    const randomString = this.randomString(7);
    // console.log("Random string: " + randomString);
    const confirmationString = this.languageService.getTranslation(_('device.sessionRestartConfirmation'));
    if (confirm(confirmationString)) {
      if (!this.isManager()) {
        // We should never get here (button is not shown for non managers), but for extra safety
        return;
      }
      const request: Observable<object> = this.deviceService.restartCompactorSession(id, randomString);
      request.subscribe(
        (response) => {
          // console.log('HTTP Response: ' + response + ' | ' + JSON.stringify(response));
        },
        (err) => {
          console.log('HTTP Error: ' + err + ' | ' + JSON.stringify(err));
          this.submitSessionRestartResponse = this.languageService.getTranslation(_('settings.submittedSessionRestartFailed'));
        },
        () => {
          this.submitSessionRestartResponse = this.languageService.getTranslation(_('settings.submittedSessionRestart'));
          const timestamp = Date.now();
          // console.log("Current Timestamp: " + timestamp);
          const sessionRestarts: SessionRestart = JSON.parse(localStorage.getItem('sessionRestarts')) || {};
          sessionRestarts[id] = timestamp;
          localStorage.setItem('sessionRestarts', JSON.stringify(sessionRestarts));
          // console.log('HTTP Request completed.');
        }
      );
    }
  }

  // Generate a random string
  private randomString(len: number) {
    const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < len; i++) {
        const randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }
}
