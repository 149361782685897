import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { CompactorFills, CompactorSessions } from 'src/app/models/device';
import { Compactor, CompactorEvents, CompactorEvent } from 'src/app/models/device';
import { DeviceAPIService } from 'src/app/services/device-api.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { LanguageService } from 'src/app/services/language.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  templateUrl: './deviceview.component.html',
  styleUrls: ['./deviceview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceViewComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public device$: Observable<Compactor>;
  public fills$: Observable<CompactorFills>;
  public fills: CompactorFills;
  public events$: Observable<CompactorEvents>;
  public events: CompactorEvents;
  public sessions$: Observable<CompactorSessions>;
  public sessions: CompactorSessions;

  public list: any;
  public selectedEvents: any; // Array of selected events

  public eventSorting: string = 'date';
  public eventShowMax: string = 'showAll';
  public eventOrder: string = 'DESC'; // descending
  public eventFilter: string = 'showAll';

  public sessionSorting: string = 'createdAt';
  public sessionShowMax: string = 'showAll';
  public sessionOrder: string = 'DESC'; // descending
  public showAmounts: string[];

  public fillsType: string = 'DEFAULT'; // DEFAULT (capped), CALCULATED (uncapped)

  public fillsPeriod: string = 'DEFAULT'; // DEFAULT, DAY, WEEK, MONTH, MONTHS-3
  public eventsPeriod: string = 'DEFAULT'; // DEFAULT, DAY, WEEK, MONTH, MONTHS-3
  public sessionsPeriod: string = 'DEFAULT'; // DEFAULT, DAY, WEEK, MONTH, MONTHS-3
  public globalPeriod: string = 'DEFAULT'; // DEFAULT, DAY, WEEK, MONTH, MONTHS -3

  // graph
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: (ChartOptions);
  public lineChartColors: Color[] = [
    {
      borderColor: 'rgba(10, 88, 147, 1)',
      backgroundColor: 'rgba(10, 88, 147, 0.22)',
      borderWidth: 1.5,
      pointBorderWidth: 1.5,
      pointBorderColor: 'rgba(10, 88, 147, 1)',
      pointStyle: 'dash',
    },
  ];
  public lineChartLegend = false;
  public lineChartType = 'line';
  public lineChartPlugins = {ChartAnnotation};

  private currentThreshold = 80;

  constructor(private deviceService: DeviceAPIService, private languageService: LanguageService,
              private activatedRoute: ActivatedRoute, private utils: UtilsService) {
    this.device$ = this.deviceService.getOne(this.activatedRoute.snapshot.params.id);
    this.refreshAllData();
    this.showAmounts = ['5', '10', '25', '50']; // all gets added in the HTML
    this.initEventList();
    this.initGraph();
   }

  private initEventList() {
    this.list = [
      {name: 'All', checked: false},
      {name: 'EmergencyOff', checked: false},
      {name: 'EmergencyOn', checked: false},
      {name: 'ContainerGone', checked: false},
      {name: 'ContainerOn', checked: false},
      {name: 'ContainerFull', checked: false},
      {name: 'HitchOn', checked: false},
      {name: 'HitchOff', checked: false},
      {name: 'PowerOn', checked: false},
      {name: 'PowerOff', checked: false},
      {name: 'AlmostFullIn', checked: false},
      {name: 'AlmostFullInReset', checked: false},
      {name: 'ManualOn', checked: false},
      {name: 'ManualOff', checked: false},
      {name: 'ManualRestart', checked: false},
    ];
  }

  /**
   * Initializing Fill Graphs
   * - Annotations: These are the horizontal red line at 100% and the orange line at the threshold (f.e. 80%)
   * - Tooltips: When you hover over data points these get shown (show date and percentage)
   * - Scales: Dates on the x-as in format 'Oct 3', percentages on the y-as in the format 20, 40, 60, 80, 100.
   */
  private initGraph() {
    // const timeUnit = this.globalPeriod === 'DAY' || this.globalPeriod === 'WEEK' ? 'hour' : 'day';
    const timeUnit = this.fillsPeriod === 'DAY' ? 'hour' : 'day';
    let fillGradeString: string = this.languageService.getTranslation(_('gauge.fillPercentage'));
    const cyclesString: string = this.languageService.getTranslation(_('session.cycles'));
    const pressureString: string = this.languageService.getTranslation(_('session.pressure'));
    const extensiveFills: boolean = this.fillsType === 'EXTENSIVE';
    const tempFills = this.fills ? this.fills.fills : null;
    if (!fillGradeString || fillGradeString.startsWith('gauge')) {
      fillGradeString = 'Percentage'; // fix for when translation loads incorrectly
    }
    const dateOptions = {
      day: 'numeric', month: 'long', year: 'numeric',
      hour: '2-digit', minute: '2-digit'
    };
    this.lineChartOptions = {
      annotation: {
        annotations: [
        {
          borderColor: 'red',
          borderWidth: 2,
          mode: 'horizontal',
          type: 'line',
          value: 100,
          scaleID: 'y-axis-0'
        }, {
          borderColor: 'rgba(250, 140, 0, 1)',
          borderWidth: 2,
          mode: 'horizontal',
          type: 'line',
          value: this.currentThreshold,
          scaleID: 'y-axis-0'
        } ]
      },
      tooltips: {
        mode: 'label',
        titleFontSize: 16,
        bodyFontSize: 12,
        callbacks: {
          label(items, data) {
            const newDate = new Date(items.label);
            if (extensiveFills && tempFills) {
              const index = items.index;
              const cycles = tempFills[index] && tempFills[index].cycles ? tempFills[index].cycles : null;
              const pressure = tempFills[index] && tempFills[index].pressure ? tempFills[index].pressure : null;
              return [newDate.toLocaleDateString('en-GB', dateOptions),
                cyclesString + ': ' + (cycles ? cycles : '-'),
                pressureString + ': ' + (pressure ? pressure.toFixed(2) + '%' : '-')
              ];
            } else {
              return newDate.toLocaleDateString('en-GB', dateOptions);
            }
          },
          title(items, data) {
            return fillGradeString + ': ' + items[0].yLabel + ' %';
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          type: 'time',
          display: true,
          time: {
            unit: timeUnit,
            displayFormats: {
              hour: 'kk:mm',
              day: 'MMM D'
            }
          }
        }],
        yAxes: [{
          type: 'linear',
          display: true,
          ticks: {
            min: 0,
            max: 120,
            callback(value, index, values) {
              return value + ' %';
            }
          },
        }],
      },
    } as ChartOptions;
  }

  ngOnInit() {
    this.device$.subscribe((device: Compactor) => {
      if (device.compactor.settings) {
        this.currentThreshold = device.compactor.settings.almostfull_threshold;
        this.initGraph();
      }
    });
    this.subscriptions.push(this.events$.subscribe(d => null)); // Subscribe and assign data early
    this.subscriptions.push(this.sessions$.subscribe(d => null)); // Subscribe and assign data early
    this.fillData();
  }

  ngOnDestroy() {
    // Unsubscribe all subscriptions, to prevent continuous creation of new subscriptions as navigation changes between views
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.length = 0;
  }

  private fillData() {
    this.subscriptions.push(this.fills$.subscribe(d => {
      this.fills = d;
      const dataset1 = [];
      if (this.fills && this.fills.fills) {
        this.fills.fills.forEach(entry => {
          dataset1.push(
            { x: entry.timestamp * 1000,
              y: entry.percentage.toFixed(2)
            });
        });
        this.initGraph();
      }
      this.lineChartData = [
        { data: dataset1,
          cubicInterpolationMode: 'default',
          lineTension: 0
        }
      ];
    }
    ));
  }

  // Set the current sorting colum for Sessions to the given one, and switch the order if we clicked on the same one
  public setSessionSorting(order: string) {
    if (this.sessionSorting === order) {
      // Switch sorting order of the Sessions table between ASC and DESC
      this.sessionOrder = (this.sessionOrder === 'ASC' ? 'DESC' : 'ASC');
    }
    this.sessionSorting = order;
  }

   // Set the current sorting colum for Events to the given one, and switch the order if we clicked on the same one
   public setEventSorting(order: string) {
    if (this.eventSorting === order) {
      // Switch sorting order of the Events table between ASC and DESC
      this.eventOrder = (this.eventOrder === 'ASC' ? 'DESC' : 'ASC');
    }
    this.eventSorting = order;
  }

  // Returns the correct ASC or DESC fa icon (or none) for the Event Table headers
  public getEventSortIcon(column: string) {
    if (this.eventSorting === column) {
      return this.eventOrder === 'ASC' ? 'fa-sort-up up' : 'fa-sort-down down';
    } else {
      return '';
    }
  }

  // Returns the correct ASC or DESC fa icon (or none) for the Session Table headers
  public getSessionSortIcon(column: string) {
    if (this.sessionSorting === column) {
      return this.sessionOrder === 'ASC' ? 'fa-sort-up up' : 'fa-sort-down down';
    } else {
      return '';
    }
  }

  // Chooses which icon to represent the given Event
  public getEventIcon(compactorEvent: CompactorEvent) {
    return this.utils.getEventIcon(compactorEvent);
  }

  // Chooses if the icon should be red (error), green (ok) or gray (disabled)
  public getEventIconColor(compactorEvent: CompactorEvent) {
    switch (compactorEvent.event) {
      case 'EmergencyOff': return 'ok';
      case 'EmergencyOn': return 'error';
      case 'ContainerGone': return 'error';
      case 'ContainerOn': return 'ok';
      case 'ContainerFull': return 'error';
      case 'HitchOn': return 'error';
      case 'HitchOff': return 'ok';
      case 'PowerOn': return 'ok';
      case 'PowerOff': return 'error';
      case 'AlmostFull': return 'error blue';
      case 'AlmostFullIn': return 'error blue';
      case 'AlmostFullInReset': return 'ok';
      case 'ManualOff': return 'ok';
      case 'ManualOn': return 'error';
      case 'ManualRestart': return 'error';
      default: return 'disabled';
    }
  }

  // Trasnlate Event Name ("HitchOn", etc"), if one is available
  public translateEvent(compactorEvent: CompactorEvent) {
    if (!compactorEvent) {
      return this.languageService.getTranslation(_('event.Unknown'));
    }
    let translated: string;
    switch (compactorEvent.event) {
      case 'EmergencyOff': translated = this.languageService.getTranslation(_('event.EmergencyOff')); break;
      case 'EmergencyOn': translated = this.languageService.getTranslation(_('event.EmergencyOn')); break;
      case 'ContainerGone': translated = this.languageService.getTranslation(_('event.ContainerGone')); break;
      case 'ContainerOn': translated = this.languageService.getTranslation(_('event.ContainerOn')); break;
      case 'ContainerFull': translated = this.languageService.getTranslation(_('event.ContainerFull')); break;
      case 'HitchOn': translated = this.languageService.getTranslation(_('event.HitchOn')); break;
      case 'HitchOff': translated = this.languageService.getTranslation(_('event.HitchOff')); break;
      case 'PowerOn': translated = this.languageService.getTranslation(_('event.PowerOn')); break;
      case 'PowerOff': translated = this.languageService.getTranslation(_('event.PowerOff')); break;
      case 'AlmostFull': translated = this.languageService.getTranslation(_('event.AlmostFull')); break;
      case 'AlmostFullIn': translated = this.languageService.getTranslation(_('event.AlmostFullIn')); break;
      case 'AlmostFullInReset': translated = this.languageService.getTranslation(_('event.AlmostFullInReset')); break;
      case 'ManualOff': translated = this.languageService.getTranslation(_('event.ManualOff')); break;
      case 'ManualOn': translated = this.languageService.getTranslation(_('event.ManualOn')); break;
      case 'ManualRestart': translated = this.languageService.getTranslation(_('event.ManualRestart')); break;
    }
    if (!translated) {
      console.log('Unknown event: ' + compactorEvent.event); // For debugging purposes, should not happen
    }
    return translated ? translated : this.languageService.getTranslation(_('event.Unknown'));
  }

  public getHumanReadableFlag(flags: number, flag: number): string {
    return (flags & flag) ? 'Yes' : 'No';
  }

  public getFlag(flags: number, flag: number): boolean {
    return !!(flags & flag);
  }

  // Used in HTML, limits number of events in the table to the chosen number or max 1000
  public showMaxEvents(): number {
    return this.eventShowMax == null || this.eventShowMax === 'showAll' ? 1000 : +this.eventShowMax;
  }

  // Used in HTML, limits number of session in the table to the chosen number or max 1000
  public showMaxSessions(): number {
    return this.sessionShowMax == null || this.sessionShowMax === 'showAll' ? 1000 : +this.sessionShowMax;
  }

  // ==== REFRESH DATA METHODS ====

  // Refreshing all Data and changing the periods to the same period
  public refreshAllData(): void {
    this.fillsPeriod = this.globalPeriod;
    this.eventsPeriod = this.globalPeriod;
    this.sessionsPeriod = this.globalPeriod;
    this.refreshFillData();
    this.refreshEventsData();
    this.refreshSessionsData();
    if (this.events && this.events.events) {
      console.log('Events: ' + this.events.events.length);
    }
  }

  // Refreshing Fills Data
  public refreshFillData(): void {
    // console.log("Refreshing Fill Data. FillsType = " + this.fillsType + " Period = " + this.fillsPeriod);
    this.initGraph();
    const sanitized = this.fillsType === 'DEFAULT'; // DEFAULT = SANITIZED (capped)
    let timestamp = this.utils.getTimestamp(this.fillsPeriod);
    if (timestamp == null) {
      timestamp = '-1'; // sensible defaults
    }
    if (this.fillsType === 'EXTENSIVE') { // extensive/uitgebreid
      this.fills$ = this.deviceService.getExtensiveFills(this.activatedRoute.snapshot.params.id, timestamp);
    } else { // default/sanitized - calculated
      this.fills$ = this.deviceService.getFillsExtra(this.activatedRoute.snapshot.params.id, timestamp, sanitized);
    }
    this.fillData();
  }

  // Refreshing Events Data
  public refreshEventsData(): void {
    // console.log("Refreshing Events Data. Period = " + this.eventsPeriod);
    const timestamp = this.utils.getTimestamp(this.eventsPeriod);
    if (timestamp == null) {
      // For now we only use start_at=-1 for FILLS and SESSIONS
      this.events$ = this.deviceService.getEvents(this.activatedRoute.snapshot.params.id);
      // timestamp = '-1'; // sensible defaults
    } else {
      this.events$ = this.deviceService.getEventsExtra(this.activatedRoute.snapshot.params.id, timestamp);
    }
  }

  // Refreshing Session Data
  public refreshSessionsData(): void {
    // console.log("Refreshing Sessions Data. Period = " + this.sessionsPeriod);
    let timestamp = this.utils.getTimestamp(this.sessionsPeriod);
    if (timestamp == null) {
      timestamp = '-1'; // sensible defaults
    }
    // TODO: If ?limit also works without ?start_at we have to change this
    const limit: number = Number(this.sessionShowMax);
    this.sessions$ = this.deviceService.getSessionsExtra(this.activatedRoute.snapshot.params.id, timestamp, limit);
  }

  // Pulldown with selected events has changed, update filters
  public shareCheckedList(item: any[]) {
    if (item.length === 0) {
      this.selectedEvents = [];
      this.eventFilter = 'showAll';
      this.initEventList();
    } else {
      // console.log("Changed Event list: " + item);
      const myClonedArray  = Object.assign([], item); // Needed to make the filter re-apply (updating array doesn't update reference)
      this.selectedEvents = myClonedArray;
    }
  }

  public shorterDeviceName(name: string) {
    return (name.length > 35 ? name.substr(0, 35) : name);
  }
}
