import { NewAuthService } from 'src/app/services/oauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public logo: string = environment.logo;
  public isMobile: boolean;

  constructor(private languageService: LanguageService,
              private authService: NewAuthService,
              private responsiveService: ResponsiveService,
              private router: Router) {
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  // Used for on-the-fly language switching
  useLanguage(language: string) {
    return this.languageService.useLanguage(language);
  }

  getLanguage() {
    return this.languageService.getLanguage();
  }

  loggedIn() {
    return this.authService.isLoggedin();
  }

  getUsername() {
    return this.authService.getUsername();
  }

  signOff() {
    this.authService.logout();
  }

  navigateTo(path: string) {
    this.router.navigate([path]);
  }
}
