import { Pipe, PipeTransform } from '@angular/core';
import { CompactorAlarmActions } from '../../models/device';

// Sorter Pipe for the Alarms Table on the Notifications Page
@Pipe({
  name: 'alarmSorter'
})
export class AlarmSorterPipe implements PipeTransform {
  transform(alarms: CompactorAlarmActions, orderStr = '', sorting: string): CompactorAlarmActions {
    if (!alarms) return;
    const order: number = orderStr === 'ASC' ? -1 : 1;
    switch (sorting) {
      case 'alarm': default:
        return alarms.sort((a, b) => {
          if (a.id > b.id) return -order;
          if (a.id < b.id) return order;
          return 0;
        });
      case 'type':
        return alarms.sort((a, b) => {
          if (a.type > b.type) return -order;
          if (a.type < b.type) return order;
          return 0;
        });
      case 'event':
      return alarms.sort((a, b) => {
        if (a.event > b.event) return -order;
        if (a.event < b.event) return order;
        return 0;
      });
      case 'recipient':
      return alarms.sort((a, b) => {
        if (a.recipients > b.recipients) return -order;
        if (a.recipients < b.recipients) return order;
        return 0;
      });
      case 'template':
      return alarms.sort((a, b) => {
        if (!a.template_id) return order;
        if (!b.template_id) return -order;
        if (Number(a.template_id) > Number(b.template_id)) return -order;
        if (Number(a.template_id) < Number(b.template_id)) return order;
        return 0;
      });
    }
  }
}
