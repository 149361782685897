export enum DeviceStatusFlag {
  OFFLINE =  1,
  EMERGENCY = 2,
  ERROR = 4,
  CONTAINER = 8,
  UNPLUGGED = 16,
  BLUE_LIGHT = 32,
  FULL = 64
}

// devices
export interface Device {
  id: string;
  name: string;
  timestamp: number;
  status_flags: number;
  feature_flags: number;
  percentage: number;
  metadata?: CompactorMetaData;
  address?: Address;
  fraction?: Fraction;
  customer?: Customer;
  settings?: CompactorSettings;
  service?: CompactorInspection[];
}
export type Devices = Device[];

// compactors
export interface Compactors {
  compactors?: Devices;
}
export interface Compactor {
  compactor: Device;
}

// compactor metadata
export interface CompactorMetaData {
  description?: string;
  serial_number?: string;
  manufacturer?: string;
  model?: string;
  volume?: number;
}

// compactor settings
export interface CompactorSettings {
  almostfull_threshold: number;
  fraction_name: string;
  fraction_type: string;
}

// compactor address
export interface Address {
  street: string;
  additional?: string;
  city: string;
  postal_code: string;
  country_code: string;
  dock_number?: number;
  company?: string;
}

// compactor fraction
export interface Fraction {
  name: string;
  type?: string;
}

// compactor customer
export interface Customer {
  name: string;
  contract_number?: string;
  address?: Address;
}

// measurements
export interface DeviceMeasurement {
  timestamp: number;
  percentage: number;
  status_flags: number;
}
export type DeviceMeasurements = DeviceMeasurement[];

// fills
export interface CompactorFill {
  timestamp: number;
  percentage: number;
  pressure?: number;
  cycles?: number;
}
export interface CompactorFills {
  fills: CompactorFill[];
}

// events
export interface CompactorEvent {
  timestamp: number;
  event: string;
}
export interface CompactorEvents {
  events: CompactorEvent[];
}

// sessions
export interface CompactorSession {
  id: number;
  created_at: number;
  completed_at: number;
  cycles: number;
  percentage: number;
  mass_estimate: number;
  mass_corrected: number;
}
export interface CompactorSessions {
  sessions: CompactorSession[];
}

// measurement
export interface CompactorMeasurement {
  session_id: number;
  mass_corrected: number;
}

// alarms (notifications)
export interface CompactorAlarm {
  actions?: CompactorAlarmActions;
}
export type CompactorAlarms = CompactorAlarm[];

export interface CompactorAlarmAction {
  id?: number;
  type: string;
  event: string;
  recipients: Recipient[];
  template_id?: string;
}
export type CompactorAlarmActions = CompactorAlarmAction[];

export interface Recipient {
  address: string;
  name?: string;
}

// used for posting new alarms
export interface SingleAlarmAction {
  action: CompactorAlarmAction;
}

// templates for compactors
export interface CompactorAlarmTemplate {
  id: number;
  type: string; // EMAIL, TELEGRAM
  organisation_id?: string;
  name: string;
  subject?: string;
  body: string;
}
export interface CompactorAlarmTemplates {
  templates: CompactorAlarmTemplate[];
}

// log / boomstam
export interface CompactorLog {
  id?: number;
  created_at?: number; // timestamp
  issuer: string;
  issue: string;
  modified_at?: number; // timestmap
  response?: string;
  responder?: string;
  solved?: boolean;
}

export interface CompactorLogs {
  logitems: CompactorLog[];
}

export interface SingleCompactorLog {
  logitem: CompactorLog;
}

// service - inspections (keuringen)
export interface CompactorInspections {
  service: CompactorInspection[];
}

export interface CompactorInspection {
  type: string;
  description?: string;
  timestamp_last: number;
  timestamp_next: number;
}
