import { AuthConfig } from 'angular-oauth2-oidc';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// Basic development configuration like API paths and images
export const environment = {
  production: false,
  // apiBasePath: 'https://sense.elfii.nl:8076',
  // apiBasePath: 'https://api.elfii.nl/octopus/v1',
  apiBasePath: 'https://api.elfii.nl/octopus/demo',
  logo: 'octopus-plain.svg',
  derooijlogo: 'derooij-wit.png',
  defaultLanguage: 'en'
};

// OAuth2/OID Development Configuration
export const authConfig: AuthConfig = {
  issuer: 'https://login.elfii.nl/', // Url of the Identity Provider
  clientId: '9fcd56ad-82a2-4d6a-a20e-05af4c51ee67',
  scope: 'openid',
  redirectUri: 'https://octopusmobiel.elfii.nl/auth-callback',
  postLogoutRedirectUri: 'https://octopusmobiel.elfii.nl/sign-off',
  responseType: 'code'
};
