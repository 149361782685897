import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NewAuthService } from 'src/app/services/oauth.service';

@Component({
  templateUrl: './signoffview.component.html',
  styleUrls: ['./signoffview.component.scss']
})
export class SignOffViewComponent implements AfterViewInit {

  public logo: string = environment.logo;
  constructor(private authService: NewAuthService, private router: Router) {}

  ngAfterViewInit() {
    // this.authService.logout();
    // this.router.navigate(['/']);
  }
}
