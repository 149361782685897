import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NewAuthService } from '../services/oauth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private oauthService: NewAuthService) {  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            // console.log('This is a client side error.');
            errorMsg = `Error: ${error.error.message}`;
          } else {
            // console.log('This is a server side error.');
            const errorCode = Number(`${error.status}`);
            switch (errorCode) {
              case 401: { // Unauthorized (probably token expired)
                console.log('We are unauthorized (HTTP error 401) for this request. Redirecting to login.');
                if (this.oauthService != null) {
                  this.oauthService.login();
                }
                break;
              }
              case 403: { // We are not allowed to do this HTTP request at all, valid token or not
                console.log('We are unauthorized (HTTP error 403) for this request.');
                break;
              }
              case 500: {
                console.log('Compactor data cannot be loaded. Internal Server Error (error 500)');
                break;
              }
            }
          }
          return throwError(errorMsg);
        })
      );
  }
}
