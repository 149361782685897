import { NewAuthService } from 'src/app/services/oauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceAPIService } from 'src/app/services/device-api.service';
import { Observable, Subscription } from 'rxjs';
import { UserProfile } from 'src/app/models/user';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  templateUrl: './settingsview.component.html',
  styleUrls: ['./settingsview.component.scss']
})
export class SettingsViewComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  public profile: UserProfile;

  constructor(private deviceService: DeviceAPIService,
              private languageService: LanguageService,
              private authService: NewAuthService) {
    this.profile = this.authService.getProfile();
  }

  ngOnInit() {
    this.profile = this.authService.getProfile();
  }

  getProfile() {
    this.profile = this.authService.getProfile();
    return this.profile;
  }

  // Retrieve a human readable Role belonging to the given id
  getRole(id: number): string {
    this.profile = this.authService.getProfile();
    switch (id) {
      case 72: return this.languageService.getTranslation(_('roles.manager'));
      case 64: return this.languageService.getTranslation(_('roles.readonly'));
      // TODO: Add more roles when needed
    }
    return this.languageService.getTranslation(_('roles.unknown'));
  }

  ngOnDestroy() {
    // Unsubscribe all subscriptions, to prevent continuous creation of new subscriptions as navigation changes between views
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.length = 0;
  }
}
