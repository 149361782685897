import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';

import { SignOnViewComponent } from './views/signon/signonview.component';
import { SignOffViewComponent } from './views/signoff/signoffview.component';
import { DashboardViewComponent } from './views/dashboard/dashboardview.component';
import { DetailedViewComponent } from './views/dashboard/detailedview/detailedview.component';
import { DeviceViewComponent } from './views/device/deviceview/deviceview.component';
import { NotificationsViewComponent } from './views/device/notifications/notificationsview.component';
import { MeasurementsViewComponent } from './views/device/measurements/measurementsview.component';
import { SettingsViewComponent } from './views/settings/settingsview.component';
import { AuthCallbackComponent } from './views/auth-callback/auth-callback.component';
import { ServiceViewComponent } from './views/device/service/serviceview.component';

import { ErrorViewComponent } from './views/error/errorview.component';
import { DeviceSettingsViewComponent } from './views/device/device-settings/device-settings.component';
import { LanguageService } from 'src/app/services/language.service';

const routes: Routes = [
  // Auth/deauth routes
  {
    path: 'sign-on',
    component: SignOnViewComponent,
    data: {title: 'Sign On'}
  },
  {
    path: 'sign-off',
    component: SignOffViewComponent,
    data: {title: 'Sign Off'}
  },

  // Application functional routes
  {
    path: 'dashboard',
    component: DashboardViewComponent,
    canActivate: [AuthGuard],
    data: {title: 'Dashboard'}
  },
  {
    path: 'details',
    component: DetailedViewComponent,
    canActivate: [AuthGuard],
    data: {title: 'Detail View'}
  },
  {
    path: 'settings',
    component: SettingsViewComponent,
    canActivate: [AuthGuard],
    data: {title: 'Settings'}
  },
  {
    path: 'device/:id',
    component: DeviceViewComponent,
    canActivate: [AuthGuard],
    data: {title: 'Device Overview'}
    // TODO: Configure /notifications and /measurements paths as children of /device/:id (wouldn't work)
  },
  {
    path: 'device/:id/notifications',
    component: NotificationsViewComponent,
    canActivate: [AuthGuard],
    data: {title: 'Configure Notifications'}
  },
  {
    path: 'device/:id/measurements',
    component: MeasurementsViewComponent,
    canActivate: [AuthGuard],
    data: {title: 'Manual Measurements'}
  },
  {
    path: 'device/:id/settings',
    component: DeviceSettingsViewComponent,
    canActivate: [AuthGuard],
    data: {title: 'Device Settings'}
  },
  {
    path: 'device/:id/service',
    component: ServiceViewComponent,
    canActivate: [AuthGuard],
    data: {title: 'Service and Maintenance'}
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
    // canActivate: [AuthGuard]
  },

  { // Redirect from /null -> /dashboard (yes, ugly fix)
    path: 'null',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  { // Redirect from / -> starting view (dashboard)
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },

  { // Anything not matching routes above uses the error view (404 page in traditional setups)
    path: '**',
    component: ErrorViewComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
