import { Component, OnInit, OnDestroy } from '@angular/core';
import { Compactor, CompactorSessions, CompactorMeasurement, CompactorSession } from 'src/app/models/device';
import { DeviceAPIService } from 'src/app/services/device-api.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { LanguageService } from 'src/app/services/language.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

// Component for manual measurements for a specific device
@Component({
  templateUrl: './measurementsview.component.html',
  styleUrls: ['./measurementsview.component.scss']
})
export class MeasurementsViewComponent implements OnInit, OnDestroy {
  private device_id: string;
  private subscriptions: Subscription[] = [];
  public device$: Observable<Compactor>;
  public sessions$: Observable<CompactorSessions>;
  private sessions: CompactorSessions;
  public measurementForm: FormGroup;
  public submitResponse: string;

  public showAmounts: string[];

  public sessionSorting: string = 'createdAt';
  public sessionShowMax: string = 'showAll';
  public sessionOrder: string = 'DESC'; // descending

  public sessionCompletedAt = null;
  public sessionMassEstimate = null;

  constructor(private deviceService: DeviceAPIService,
              private activatedRoute: ActivatedRoute,
              private languageService: LanguageService,
              private formBuilder: FormBuilder) {
    this.device_id = this.activatedRoute.snapshot.params.id;
    this.device$ = this.deviceService.getOne(this.device_id);
    this.sessions$ = this.deviceService.getSessions(this.activatedRoute.snapshot.params.id);
    this.resetSessionForm(null);
    this.submitResponse = '';
    this.showAmounts = ['5', '10', '25', '50']; // all gets added in the HTML
  }

  ngOnInit() {
    this.subscriptions.push(this.device$.subscribe(d => null));
    this.subscriptions.push(this.sessions$.subscribe(d => this.sessions = d));
  }

  ngOnDestroy() {
    // Unsubscribe all subscriptions, to prevent continuous creation of new subscriptions as navigation changes between views
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.length = 0;
  }

  // Submitting form for setting a new manual measurement
  public onSubmit(measurementForm: FormGroup) {
    const sessionId: number = Number(measurementForm.get('session').value);
    const massCorrected: number = Number(measurementForm.get('measurement').value);
    // Validate form data
    if (!sessionId || isNaN(sessionId)) {
      this.submitResponse = this.languageService.getTranslation(_('session.invalidSession'));
      return;
    }
    if (!massCorrected || isNaN(massCorrected) || massCorrected <= 0) {
      this.submitResponse = this.languageService.getTranslation(_('measurements.invalidMeasurement'));
      return;
    }
    // Data has sensible types, going to submit data to server
    const newMeasurement: CompactorMeasurement = {
      session_id: sessionId,
      mass_corrected: massCorrected,
    };
    const request: Observable<object> = this.deviceService.postNewMeasurement(this.device_id, newMeasurement);
    request.subscribe(
      (reqResponse) => {
        // console.log('HTTP Response: ' + reqResponse + ' | ' + JSON.stringify(reqResponse));
      },
      (reqError) => {
        // console.log('HTTP Error: ' + reqError + ' | ' + JSON.stringify(reqError));
        this.submitResponse = this.languageService.getTranslation(_('measurements.submittedMeasurementFailed'));
      },
      () => {
        // console.log('HTTP Post Log Completed.');
        this.refreshData();
        this.measurementForm.reset();
        this.submitResponse = this.languageService.getTranslation(_('measurements.submittedMeasurement'));
      }
    );
  }

  // User clicked on a session in the table, update form
  public clickSession(clickedSession: CompactorSession) {
    if (clickedSession == null) return;
    const id = clickedSession.id;
    this.resetSessionForm(clickedSession);
    this.submitResponse = null;
  }

  // User changed session in the form below the table
  public clickSessionForm(sessionId: number) {
    if (this.sessions && this.sessions.sessions) {
      const newSession: CompactorSession = this.sessions.sessions.find(o => o.id == sessionId);
      this.resetSessionForm(newSession);
    } else {
      console.log('Something went wrong finding the correct Session.');
    }
   }

  // Set the Session Form Data to the given session
  private resetSessionForm(session: CompactorSession) {
    if (session) {
      // Set new session data
      this.measurementForm = this.formBuilder.group({
        session: [session.id],
        measurement: ['', Validators.required]
      });
      this.sessionCompletedAt = String(session.completed_at);
      this.sessionMassEstimate = String(session.mass_estimate);
    } else {
      // Clear form
      this.measurementForm = this.formBuilder.group({
        session: [''],
        measurement: ['', Validators.required]
      });
      this.sessionCompletedAt = null;
      this.sessionMassEstimate = null;
    }
   }

  public getSession(): CompactorSession {
    const sessionId: number = Number(this.measurementForm.get('session').value);
    return null;
  }

  // Set the current sorting colum for Sessions to the given one, and switch the order if we clicked on the same one
  public setSessionSorting(order: string) {
    if (this.sessionSorting === order) {
      // Switch sorting order of the Sessions table between ASC and DESC
      this.sessionOrder = (this.sessionOrder === 'ASC' ? 'DESC' : 'ASC');
    }
    this.sessionSorting = order;
  }

  // Returns the correct ASC or DESC fa icon (or none)
  public getIcon(column: string) {
    if (this.sessionSorting === column) {
      return this.sessionOrder === 'ASC' ? 'fa-sort-up' : 'fa-sort-down';
    } else {
      return '';
    }
  }

  public getHumanReadableFlag(flags: number, flag: number): string {
    return (flags & flag) ? 'Yes' : 'No';
  }

  public getFlag(flags: number, flag: number): boolean {
    return !!(flags & flag);
  }

  public refreshData(): void {
    this.sessions$ = this.deviceService.getSessions(this.activatedRoute.snapshot.params.id);
  }
}
