import { Pipe, PipeTransform } from '@angular/core';
import { CompactorLog } from '../../models/device';

// Sorter Pipe for the Log Table in de Service Page
@Pipe({
  name: 'logSorter'
})
export class LogSorterPipe implements PipeTransform {
  transform(logs: CompactorLog[], orderStr = '', sorting: string): CompactorLog[] {
    if (!logs) return;
    const order: number = orderStr === 'ASC' ? -1 : 1;
    switch (sorting) {
      case 'id': default:
        return logs.sort((a, b) => {
          if (a.id > b.id) return -order;
          if (a.id < b.id) return order;
          return 0;
        });
      case 'issue':
        return logs.sort((a, b) => {
          if (a.issue > b.issue) return -order;
          if (a.issue < b.issue) return order;
          return 0;
        });
      case 'response':
        return logs.sort((a, b) => {
          if (a.response > b.response) return -order;
          if (a.response < b.response) return order;
          return 0;
        });
      case 'issuer':
        return logs.sort((a, b) => {
          if (a.issuer > b.issuer) return -order;
          if (a.issuer < b.issuer) return order;
          return 0;
        });
      case 'created_at':
        return logs.sort((a, b) => {
          if (a.created_at > b.created_at) return -order;
          if (a.created_at < b.created_at) return order;
          return 0;
        });
      case 'modified_at':
        return logs.sort((a, b) => {
          if (a.modified_at > b.modified_at) return -order;
          if (a.modified_at < b.modified_at) return order;
          return 0;
        });
      case 'solved':
        return logs.sort((a, b) => {
          if (a.solved > b.solved) return -order;
          if (a.solved < b.solved) return order;
          return 0;
        });
    }
  }
}
