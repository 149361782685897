import { Component } from '@angular/core';
import { NewAuthService } from 'src/app/services/oauth.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { OnInit, OnDestroy } from '@angular/core';

@Component({
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit, OnDestroy  {
  public logo: string = environment.logo;

  constructor(private oauthService: NewAuthService, private router: Router) {}

  ngOnInit() {
    // console.log('[Auth Callback] Arrived at auth-callback');
    // check for error
    if (this.router.url.indexOf('error') >= 0) {
      // console.log('[Auth Callback] Error arriving at Callback, not redirecting.');
      return;
    }
    let redirectUrl = localStorage.getItem('url');
    if (redirectUrl != null) {
      // console.log('Redirecting to: ' + redirectUrl);
      if (redirectUrl.startsWith('/sign')) {
        // This should probably never happen, but could cause a loop. Redirecting to dashboard
        redirectUrl = '/dashboard';
        // console.log('Fixing redirect to: ' + redirectUrl);
      }
    } else {
      // console.log("No URL found, redirecting to default: /dashboard");
      redirectUrl = '/dashboard';
    }
    setTimeout(() => {
      this.oauthService.loginSuccess();
      console.log('[Auth Callback] Redirecting to: ' + redirectUrl);
      // here we want to check if we need to navigate to a specific compactor
      this.router.navigate([redirectUrl]);
    }, 1000);
  }

  ngOnDestroy() {
  }

  get token() {
    return this.oauthService.getToken();
  }
}
