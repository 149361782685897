import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { OAuthModule } from 'angular-oauth2-oidc';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ChartsModule } from 'ng2-charts';

import { TokenAuthInterceptor } from './interceptors/token-auth.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DeviceAPIService } from './services/device-api.service';
import { DeviceService } from './services/device.service';
import { LanguageService } from './services/language.service';
import { UtilsService } from './services/utils.service';
import { ResponsiveService } from './services/responsive.service';

import { DashboardViewComponent } from './views/dashboard/dashboardview.component';
import { DeviceViewComponent } from './views/device/deviceview/deviceview.component';
import { NotificationsViewComponent } from './views/device/notifications/notificationsview.component';
import { MeasurementsViewComponent } from './views/device/measurements/measurementsview.component';
import { ServiceViewComponent } from './views/device/service/serviceview.component';

import { SettingsViewComponent } from './views/settings/settingsview.component';
import { DetailedViewComponent } from './views/dashboard/detailedview/detailedview.component';

import { ErrorViewComponent } from './views/error/errorview.component';
import { SignOffViewComponent } from './views/signoff/signoffview.component';
import { SignOnViewComponent } from './views/signon/signonview.component';

import { GaugeComponent } from './components/gauge/gauge.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DeviceSideBarComponent } from './components/device-sidebar/device-sidebar.component';

import { DeviceFilterPipe } from './pipes/filters/devicefilter.pipe';
import { EventFilterPipe } from './pipes/filters/eventfilter.pipe';

import { DeviceSorterPipe } from './pipes/sorters/devicesorter.pipe';
import { EventSorterPipe } from './pipes/sorters/eventsorter.pipe';
import { SessionSorterPipe } from './pipes/sorters/sessionsorter.pipe';
import { AlarmSorterPipe } from './pipes/sorters/alarmsorter.pipe';
import { LogSorterPipe } from './pipes/sorters/logsorter.pipe';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthCallbackComponent } from './views/auth-callback/auth-callback.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { DeviceSettingsViewComponent } from './views/device/device-settings/device-settings.component';

import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

import enBundle from '../assets/i18n/en.json';
import { ServiceSorterPipe } from './pipes/sorters/servicesorter.pipe';
import { GaugeSmallComponent } from './components/gauge-small/gauge-small.component';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params) {
      const newTranslation = enBundle[params.key];
      // console.log("Changing translation for " + params.key + " to: " + newTranslation);
      return newTranslation;
    } else {
      return '';
    }
  }
}

const viewDeclarations = [
  DashboardViewComponent,
  DetailedViewComponent,
  SettingsViewComponent,
  DeviceViewComponent,
  ServiceViewComponent,
  DeviceSettingsViewComponent,
  NotificationsViewComponent,
  MeasurementsViewComponent,
  DeviceSideBarComponent,
  ErrorViewComponent,
  SignOffViewComponent,
  SignOnViewComponent,
  AuthCallbackComponent
];

const componentDeclarations = [
  GaugeComponent,
  GaugeSmallComponent,
  HeaderComponent,
  FooterComponent,
  MultiSelectComponent
];

const pipeDeclarations = [
  DeviceFilterPipe,
  EventFilterPipe,
  DeviceSorterPipe,
  EventSorterPipe,
  SessionSorterPipe,
  AlarmSorterPipe,
  LogSorterPipe,
  ServiceSorterPipe
];

const serviceProviders = [
  DeviceAPIService,
  DeviceService,
  LanguageService,
  ResponsiveService,
  UtilsService,
  Title
];

@NgModule({
  declarations: [
    AppComponent,
    ...viewDeclarations,
    ...componentDeclarations,
    ...pipeDeclarations
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    OAuthModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
      useDefaultLang: false,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    ...serviceProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

