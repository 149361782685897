import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResponsiveService } from './services/responsive.service';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private translate: TranslateService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private responsiveService: ResponsiveService,
              private titleService: Title) {
    translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      if (isMobile) {
        // console.log('Mobile device detected');
      } else {
        // console.log('Desktop detected');
      }
    });
    this.onResize();
    this.updateTitle();
  }

  // Update the Page Title that is set in the Router Service
  private updateTitle() {
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.setDocTitle('Octopus - ' + ttl);
      });
  }

  onResize() {
    this.responsiveService.checkWidth();
  }

  // Change the Page Title to the given string
  setDocTitle(title: string) {
    // console.log('Old Page Title -> ' + this.titleService.getTitle());
    // console.log('New Page Title -> ' + title);
    this.titleService.setTitle(title);
 }
}
