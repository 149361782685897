import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CompactorEvent } from 'src/app/models/device';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector : 'app-multi-select',
    templateUrl : './multi-select.component.html',
    styleUrls : ['./multi-select.component.scss']
})

/**
 * Helper component for the Show Event Dropdown menu
 */
export class MultiSelectComponent {
  @Input() list: any[];
  @Output() shareCheckedList = new EventEmitter();
  checkedList: any[];
  currentSelected: {};
  showDropDown: boolean;

  constructor(private languageService: LanguageService, private utils: UtilsService) {
    this.checkedList = [];
  }

  // Trasnlate Event Name ("HitchOn", etc"), if one is available
  public translateEvent(eventName: string) {
    let translated: string = 'Unknown';
    switch (eventName) {
      case 'EmergencyOff': translated = this.languageService.getTranslation(_('event.EmergencyOff')); break;
      case 'EmergencyOn': translated = this.languageService.getTranslation(_('event.EmergencyOn')); break;
      case 'ContainerGone': translated = this.languageService.getTranslation(_('event.ContainerGone')); break;
      case 'ContainerOn': translated = this.languageService.getTranslation(_('event.ContainerOn')); break;
      case 'ContainerFull': translated = this.languageService.getTranslation(_('event.ContainerFull')); break;
      case 'HitchOn': translated = this.languageService.getTranslation(_('event.HitchOn')); break;
      case 'HitchOff': translated = this.languageService.getTranslation(_('event.HitchOff')); break;
      case 'PowerOn': translated = this.languageService.getTranslation(_('event.PowerOn')); break;
      case 'PowerOff': translated = this.languageService.getTranslation(_('event.PowerOff')); break;
      case 'AlmostFullIn': translated = this.languageService.getTranslation(_('event.AlmostFullIn')); break;
      case 'AlmostFullInReset': translated = this.languageService.getTranslation(_('event.AlmostFullInReset')); break;
      case 'ManualOff': translated = this.languageService.getTranslation(_('event.ManualOff')); break;
      case 'ManualOn': translated = this.languageService.getTranslation(_('event.ManualOn')); break;
      case 'ManualRestart': translated = this.languageService.getTranslation(_('event.ManualRestart')); break;
      case 'All': translated = this.languageService.getTranslation(_('event.allEvents')); break;
    }
    return translated;
  }

  public getSelectedValue(status: boolean, value: string) {
    if (status) {
      this.checkedList.push(value);
    } else {
      const index = this.checkedList.indexOf(value);
      this.checkedList.splice(index, 1);
    }
    if (value === 'All') {
      this.checkedList = [];
      this.currentSelected = [];
      // console.log('Cleared list');
      this.shareCheckedList.emit(this.checkedList);

    } else {
      this.currentSelected = {checked: status, name: value};
      this.shareCheckedlist();
    }
  }

  public shareCheckedlist() {
    this.shareCheckedList.emit(this.checkedList);
  }

  // Chooses which icon to represent the given Event
  public getEventIcon(compactorEvent: string) {
    return this.utils.getEventIconbyName(compactorEvent);
  }

  // Chooses if the icon should be red (error), green (ok) or gray (disabled)
  public getEventIconColor(compactorEvent: string) {
    switch (compactorEvent) {
      case 'EmergencyOff': return 'ok';
      case 'EmergencyOn': return 'error';
      case 'ContainerGone': return 'error';
      case 'ContainerOn': return 'ok';
      case 'ContainerFull': return 'error';
      case 'HitchOn': return 'error';
      case 'HitchOff': return 'ok';
      case 'PowerOn': return 'ok';
      case 'PowerOff': return 'error';
      case 'AlmostFull': return 'error blue';
      case 'AlmostFullIn': return 'error blue';
      case 'AlmostFullInReset': return 'ok';
      case 'ManualOff': return 'ok';
      case 'ManualOn': return 'error';
      default: return 'disabled';
    }
  }
}
