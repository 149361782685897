import { Pipe, PipeTransform } from '@angular/core';
import { Devices, DeviceStatusFlag } from '../../models/device';

@Pipe({
  name: 'deviceFilter'
})
export class DeviceFilterPipe implements PipeTransform {
  transform(devices: Devices, filter: string, text: string): Devices {
    switch (filter) {
      case 'online':
        return devices.filter(d => !(d.status_flags & DeviceStatusFlag.OFFLINE));
      case 'offline':
        return devices.filter(d => (d.status_flags & DeviceStatusFlag.OFFLINE));
      case 'emergency':
        return devices.filter(d => (d.status_flags & DeviceStatusFlag.EMERGENCY));
      case 'fraction':
        return (text != null && text.length > 0
          ? devices.filter(d => (d.fraction != null && d.fraction.name.toLowerCase().includes(text.toLowerCase())))
          : devices);
      case 'name':
        return (text != null && text.length > 0
          ? devices.filter(d => (d.name != null && d.name.toLowerCase().includes(text.toLowerCase())))
          : devices);
      case 'location':
        return (text != null && text.length > 0
          ? devices.filter(d => (d.address != null && d.address.city != null && d.address.city.toLowerCase().includes(text.toLowerCase())))
          : devices);
      case 'serialnumber':
        return (text != null && text.length > 0
          ? devices.filter(d => (d.metadata != null && d.metadata.serial_number != null && d.metadata.serial_number.toLowerCase().includes(text.toLowerCase())))
          : devices);
    }
    return devices;
  }
}
