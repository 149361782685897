import { Injectable } from '@angular/core';
import { Device, DeviceStatusFlag } from 'src/app/models/device';
import { LanguageService } from 'src/app/services/language.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

enum INDICATOR { DISABLED, OK, ERROR, OK_OFFLINE, ERROR_OFFLINE }

// This service is responsible for methods on devices/compactors that are usefull across the project
@Injectable()
export class DeviceService {

  // == Constructor ==
  // TODO: Consider setting the Device here to prevent giving it as a parameter for every public method
  constructor(private languageService: LanguageService) { }

  // == Private helpers ==
  private flag(state: number, flag: number): boolean { return !!(state & flag); }

  /**
   * Returns the correct CSS class names based on the indicator status
   */
  private getStatusFlagClass(device: Device, status_flag: number, feature_flag: number, flag: number) {
    const indicator = this.getStatusFlag(device, status_flag, feature_flag, flag);
    switch (indicator) {
      case INDICATOR.DISABLED: return 'disabled'; // gray
      case INDICATOR.OK: return 'ok'; // green
      case INDICATOR.ERROR: return 'error'; // red
      case INDICATOR.OK_OFFLINE: return 'ok-dark'; // last known status was ok (device is offline), make dark green
      case INDICATOR.ERROR_OFFLINE: return 'error-dark'; // last known status was error (device is offline), make dark red
      default: return ''; // should not happen
    }
  }

  /**
   * Returns DISABLED when feature_flag is disabled (0)
   * Returns ERROR when feature_flag is enabled (1) AND status_flag is 1
   * Returns OK when feature_flag is enabled (1) AND status_flag is 0
   */
  private getStatusFlag(device: Device, status_flag: number, feature_flag: number, flag: number) {
    const statusflag = this.flag(status_flag, flag);
    if (this.isOffline(device)) {
      // return INDICATOR.DISABLED; // make icons gray when device is offline
      return (statusflag ? INDICATOR.ERROR_OFFLINE : INDICATOR.OK_OFFLINE);
    }
    const featureflag = this.flag(feature_flag, flag);
    if (!featureflag) {
      return INDICATOR.DISABLED;
    } else {
      return (statusflag ? INDICATOR.ERROR : INDICATOR.OK);
    }
  }

  // Make a more descritive tooltip based on the given indicator
  private createDescriptiveTooltip(indicator: INDICATOR, status: DeviceStatusFlag) {
    switch (indicator) {
      case INDICATOR.DISABLED:
        return this.languageService.getTranslation(_('gauge.disabled')); // gray icons
      case INDICATOR.OK: case INDICATOR.OK_OFFLINE: default:
        switch (status) {
          case DeviceStatusFlag.EMERGENCY: return this.languageService.getTranslation(_('common.no')); // EmergencyOff
          case DeviceStatusFlag.ERROR: return this.languageService.getTranslation(_('common.no')); // HitchOff
          case DeviceStatusFlag.CONTAINER: return this.languageService.getTranslation(_('gauge.tooltips.containerCoupled')); // ContainerOn
          case DeviceStatusFlag.UNPLUGGED: return this.languageService.getTranslation(_('common.on')); // PowerOn
          case DeviceStatusFlag.BLUE_LIGHT: return this.languageService.getTranslation(_('common.ok')); // BlueLightOff = Container Ok
        }
        break;
      case INDICATOR.ERROR: case INDICATOR.ERROR_OFFLINE:
        switch (status) {
          case DeviceStatusFlag.EMERGENCY: return this.languageService.getTranslation(_('common.yes')); // EmergencyOn
          case DeviceStatusFlag.ERROR: return this.languageService.getTranslation(_('common.yes')); // HitchOn
          case DeviceStatusFlag.CONTAINER: return this.languageService.getTranslation(_('gauge.tooltips.containerGone')); // ContainerGone
          case DeviceStatusFlag.UNPLUGGED: return this.languageService.getTranslation(_('common.off')); // PowerOff
          case DeviceStatusFlag.BLUE_LIGHT: return this.languageService.getTranslation(_('common.almostfull')); // Container AlmostFull
        }
    }
    return 'Error'; // should not happen
  }

  private getClass(device: Device, statusFlag: DeviceStatusFlag): INDICATOR {
    return this.getStatusFlag(device, device.status_flags, device.feature_flags, statusFlag);
  }

  // == Public methods ==

  public getIndicatorClass(device: Device, statusFlag: DeviceStatusFlag): string {
    return this.getStatusFlagClass(device, device.status_flags, device.feature_flags, statusFlag);
  }

  // Returns the correct CSS class for the given indicator using the above methods
  public offlineClass(device: Device)   {
    return this.getStatusFlag(device, device.status_flags, device.feature_flags, DeviceStatusFlag.OFFLINE);
  }
  public emergencyClass(device: Device) {
    return this.getStatusFlag(device, device.status_flags, device.feature_flags, DeviceStatusFlag.EMERGENCY);
  }
  public errorClass(device: Device)     {
    return this.getStatusFlag(device, device.status_flags, device.feature_flags, DeviceStatusFlag.ERROR);
  }
  public containerClass(device: Device) {
    return this.getStatusFlag(device, device.status_flags, device.feature_flags, DeviceStatusFlag.CONTAINER);
  }
  public unpluggedClass(device: Device) {
    return this.getStatusFlag(device, device.status_flags, device.feature_flags, DeviceStatusFlag.UNPLUGGED);
  }
  public blueLightClass(device: Device) {
    return this.getStatusFlag(device, device.status_flags, device.feature_flags, DeviceStatusFlag.BLUE_LIGHT);
  }

  public emergencyTooltip(device: Device): string {
    return this.languageService.getTranslation(_('gauge.tooltips.emergency')) + ': '
          + this.createDescriptiveTooltip(this.getClass(device, DeviceStatusFlag.EMERGENCY), DeviceStatusFlag.EMERGENCY);
  }

  public errorTooltip(device: Device): string {
    return this.languageService.getTranslation(_('gauge.tooltips.hitch')) + ': '
          + this.createDescriptiveTooltip(this.getClass(device, DeviceStatusFlag.ERROR), DeviceStatusFlag.ERROR);
  }

  public containerTooltip(device: Device): string {
    return this.languageService.getTranslation(_('gauge.tooltips.container')) + ': '
          + this.createDescriptiveTooltip(this.getClass(device, DeviceStatusFlag.CONTAINER), DeviceStatusFlag.CONTAINER);
  }

  public unpluggedTooltip(device: Device): string {
    return this.languageService.getTranslation(_('gauge.tooltips.power')) + ': '
          + this.createDescriptiveTooltip(this.getClass(device, DeviceStatusFlag.UNPLUGGED), DeviceStatusFlag.UNPLUGGED);
  }

  public blueLightTooltip(device: Device): string {
    if (this.getStatusFlag(device, device.status_flags, device.feature_flags, DeviceStatusFlag.FULL) === INDICATOR.ERROR) {
      // container full
      return this.languageService.getTranslation(_('gauge.container')) + ': ' + this.languageService.getTranslation(_('common.full'));
    } else {
      // container not full, check blue light bit
      return this.languageService.getTranslation(_('gauge.container')) + ': '
            + this.createDescriptiveTooltip(this.getClass(device, DeviceStatusFlag.BLUE_LIGHT), DeviceStatusFlag.BLUE_LIGHT);
    }
  }

  public isOffline(device: Device) { return this.flag(device.status_flags, DeviceStatusFlag.OFFLINE); }
  public isOnline(device: Device) { return !this.isOffline(device); }
}
