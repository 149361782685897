import { Component, OnInit, OnDestroy } from '@angular/core';
import { Compactor } from 'src/app/models/device';
import { DeviceAPIService } from 'src/app/services/device-api.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { LanguageService } from 'src/app/services/language.service';
import { UtilsService } from 'src/app/services/utils.service';

// Component for manual measurements for a specific device
@Component({
  selector: 'app-device-sidebar',
  templateUrl: './device-sidebar.component.html',
  styleUrls: ['./device-sidebar.component.scss']
})
export class DeviceSideBarComponent implements OnInit, OnDestroy {
  private device_id: string;
  private subscriptions: Subscription[] = [];
  public device$: Observable<Compactor>;

  constructor(private deviceService: DeviceAPIService,
              private utilsService: UtilsService,
              private languageService: LanguageService,
              private activatedRoute: ActivatedRoute) {
    this.device_id = this.activatedRoute.snapshot.params.id;
    this.device$ = this.deviceService.getOne(this.device_id);
  }

  ngOnInit() {
    this.subscriptions.push(this.device$.subscribe(d => null));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.length = 0;
  }

  readableCountry(country: string) {
    return this.utilsService.translateCountry(country);
  }

  translateFraction(fraction: string) {
    return this.utilsService.translateFraction(fraction);
  }

  // Returns true if we are on the page with the given name (f.e. 'device' or 'service')
  public isActive(button: string) {
    const url = this.activatedRoute['_routerState'].snapshot.url;
    // console.log("Current url: " + url);
    const elements = url.trim().split('/');
    if (elements.length > 3 && elements[3] === button) {
      return true;
    } else if (elements.length === 3 && elements[1] === button) {
      return true;
    }
    return false;
  }

  public toggle_visibility(id) {
    const e = document.getElementById(id);
    if (e != null) {
      const dt = document.getElementById(id + '-dt');
      if (e.style.display === 'table') {
        e.style.display = 'none';
        dt.style.borderRadius = '16px';
      } else {
        e.style.display = 'table';
        dt.style.borderRadius = '16px 16px 0 0';
      }

    }
  }
}
