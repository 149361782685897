import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { Device, Compactors, DeviceStatusFlag } from 'src/app/models/device';
import { DeviceAPIService } from 'src/app/services/device-api.service';
import { LanguageService } from 'src/app/services/language.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DeviceService } from 'src/app/services/device.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CompactorEvent } from 'src/app/models/device';

@Component({
  templateUrl: './detailedview.component.html',
  styleUrls: ['./detailedview.component.scss']
})
export class DetailedViewComponent implements OnInit, OnDestroy {
  public devices$: Observable<Compactors>;
  public devices: Compactors;

  public filter: string = 'name';
  public onlineFilter: string = '';

  public subtitle: string = null;
  private subscriptions: Subscription[] = [];
  public showAmounts: string[];

  public deviceShowMax = 'showAll';
  public deviceSorting: string = 'fill';
  public deviceOrder: string = 'DESC'; // descending

  public filterType: string = 'name';
  public filterText: string = '';
  public selectedDevice: Device;

  constructor(private deviceAPIService: DeviceAPIService,
              private router: Router,
              private routeSnapshot: ActivatedRoute,
              private languageService: LanguageService,
              public deviceService: DeviceService,
              private utils: UtilsService
              ) {
    this.devices$ = this.deviceAPIService.getAllWithSettings(); // Load data early into Observable/BehaviorSubject
    this.showAmounts = ['5', '10', '25', '50']; // all gets added in the HTML
    this.retrieveSorting();
  }

  ngOnInit() {
     // Subscribe and assign data
     this.devices$.subscribe((devices: Compactors) => {
      this.devices = devices;
      // console.log("Devices: " + JSON.stringify(devices));
     });
    // this.subscriptions.push(this.devices$.subscribe(d => this.devices = d));
  }

  ngOnDestroy() {
    // Unsubscribe all subscriptions, to prevent continuous creation of new subscriptions as navigation changes between views
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.length = 0;
  }

  public refreshData(): void {
    this.devices$ = this.deviceAPIService.getAllWithSettings();
  }

  public flag(state: number, flag: number): boolean { return !!(state & flag); }
  public isOffline(device: Device) { return this.flag(device.status_flags, DeviceStatusFlag.OFFLINE); }
  public isOnline(device: Device) { return !this.isOffline(device); }

  public isOnlineText(device: Device) {
    return this.isOnline(device) ? this.languageService.getTranslation(_('gauge.online')) : this.languageService.getTranslation(_('gauge.offline'));
  }

  // Return the correct Font Awesome icon for the given event
  public getEventIcon(device: Device, event: string) {
    let eventString = event;
    if (eventString === 'ContainerOn') {
      if (this.getContainerClass(device) === 'error' || this.getContainerClass(device) === 'error-dark') {
        eventString = 'ContainerGone';
      }
    }
    const eventObject: CompactorEvent = {timestamp: 0, event: eventString};
    return this.utils.getEventIcon(eventObject);
  }

  public getEmergencyClass(device: Device) { return this.deviceService.getIndicatorClass(device, DeviceStatusFlag.EMERGENCY); }
  public getErrorClass(device: Device)     { return this.deviceService.getIndicatorClass(device, DeviceStatusFlag.ERROR); }
  public getContainerClass(device: Device) { return this.deviceService.getIndicatorClass(device, DeviceStatusFlag.CONTAINER); }
  public getUnpluggedClass(device: Device) { return this.deviceService.getIndicatorClass(device, DeviceStatusFlag.UNPLUGGED); }

  /**
   * When FULL bit is on (error, '1'): Red lamp
   * When BLUE LIGHT bit is on (error, '1'): Blue lamp
   * When both are off: Green lamp
   */
  public getBlueLightClass(device: Device) {
    const blue = this.deviceService.getIndicatorClass(device, DeviceStatusFlag.BLUE_LIGHT);
    const full = this.deviceService.getIndicatorClass(device, DeviceStatusFlag.FULL);
    // console.log("Device: "+device.name+ " Status: " + device.status_flags);
    // console.log("Blue Light Indicator Class: " + blue);
    // console.log("FULL Bit Indicator Class: " + full);
    return (full === 'error' ? full : 'blue ' + blue);
  }

  public setDeviceSorting(newSorting: string) {
    if (newSorting === this.deviceSorting) {
      // Switch sorting order of the Devices table between ASC and DESC
      this.deviceOrder = (this.deviceOrder === 'ASC' ? 'DESC' : 'ASC');
    }
    this.deviceSorting = newSorting;
    this.updateSorting();
  }

  private retrieveSorting() {
    const storedSorting = localStorage.getItem('sorting');
    if (storedSorting != null) {
      this.deviceSorting = storedSorting;
    }
    const storedOrder = localStorage.getItem('sortingorder');
    if (storedOrder != null) {
      this.deviceOrder = storedOrder;
    }
    const storedFilter = localStorage.getItem('filter');
    if (storedFilter != null) {
      this.filter = storedFilter;
    }
    const storedFilterText = localStorage.getItem('filtertext');
    if (storedFilterText != null) {
      this.filterText = storedFilterText;
    }
  }

  public updateSorting() {
    localStorage.setItem('sorting', this.deviceSorting);
    localStorage.setItem('sortingorder', this.deviceOrder);
    localStorage.setItem('filter', this.filter);
    localStorage.setItem('filtertext', this.filterText);
  }

  public updateFilter(newFilter: string) {
    if (this.filter !== newFilter) {
      this.filterText = '';
    }
    this.filter = newFilter;
  }

  // Returns the correct ASC or DESC fa icon (or none)
  public getIcon(column: string) {
    if (this.deviceSorting === column) {
      return this.deviceOrder === 'ASC' ? 'fa-sort-up' : 'fa-sort-down';
    } else {
      return '';
    }
  }

  public onlineIconClass() {
    return this.utils.RSS;
  }

  public selectDevice(device: Device): void {
    if (this.selectedDevice == device) {
      // Device is already selected, so we 'double click' and go to the Details page
      this.showDetails(device);
    }
    this.selectedDevice = device;
  }

  public showDetails(device: Device): void {
    // Navigate to a detail page of a device
    this.router.navigate(['device', device.id]);
  }

   // Methods for retrieving the number of devices
   public totalDevices() {
    return (this.devices && this.devices.compactors ? this.devices.compactors.length : 0);
  }

  public offlineDevices() {
    return this.devicesCount(DeviceStatusFlag.OFFLINE);
  }

  public emergencyDevices() {
    return this.devicesCount(DeviceStatusFlag.EMERGENCY);
  }

  public onlineDevices() {
    return this.totalDevices() - this.offlineDevices();
  }

  private devicesCount(filterFlag: DeviceStatusFlag) {
    const result = (this.devices && this.devices.compactors ? this.devices.compactors.filter(d => (d.status_flags & filterFlag)) : null);
    return result ? result.length : 0;
  }

  public shortName(text: string) {
    const MAX_LENGTH = 27;
    if (text.length > MAX_LENGTH) {
      return text.substr(0, MAX_LENGTH) + '...';
    } else {
      return text;
    }
  }

  public threshold(device: Device): number {
    let threshold = 75;
    if (device && device.settings) {
      threshold = device.settings.almostfull_threshold;
    }
    // console.log("Almost full threshold - Device " + (device ? device.name : '') + ": " + threshold + "%");
    return threshold;
  }

  // Returns true when Device is Full
  public deviceFull(device: Device): boolean {
    const full = this.flag(device.status_flags, DeviceStatusFlag.FULL);
    // console.log("Full bit for Device " + (device ? device.name : '') + ": " + full);
    return full;
  }

  // Returns true when Device is Almost Full (Blue Light is on)
  public almostFull(device: Device): boolean {
    const threshold = this.threshold(device);
    const pct = device ? device.percentage : 0;
    return pct > threshold;
  }

  // Returns the corresponding Timestamp for the next inspection of the given type (NEN / Maintenace)
  public getServiceDate(device: Device, type: string) {
    if (device && device.service) {
      for (const service of device.service) {
        // console.log("Service: " + service.type);
        if (service.type === type) {
          const date = service.timestamp_next;
          // console.log("Returning date" + date);
          return date;
        }
      }
    }
    return null;
  }
}
