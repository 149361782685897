import { Pipe, PipeTransform } from '@angular/core';
import { CompactorEvent } from '../../models/device';

@Pipe({
  name: 'eventFilter'
})
export class EventFilterPipe implements PipeTransform {
  transform(events: CompactorEvent[], filter: string[]): CompactorEvent[] {
    // console.log("Filter: " + filter);
    if (filter == null || filter.length === 0) {
      return events;
    }
    switch (filter) {
      default:
        return events.filter(e => filter.includes(e.event));
      case []:
        return events;
    }
  }
}
