import { Component, Input } from '@angular/core';
import { Device, DeviceStatusFlag } from 'src/app/models/device';
import { AfterViewInit, ViewChild} from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UtilsService } from 'src/app/services/utils.service';
import { CompactorEvent } from 'src/app/models/device';

declare const CustomGradient: any;

// This might be a bit 'much', but it improves the readability a lot
// TODO: Might want to add a BLUE status (for the blue light led) as a seperate status
enum INDICATOR { DISABLED, OK, ERROR, OK_OFFLINE, ERROR_OFFLINE }

@Component({
  selector: 'app-gauge-small',
  templateUrl: './gauge-small.component.html',
  styleUrls: ['./gauge-small.component.scss']
})
export class GaugeSmallComponent implements AfterViewInit {
  @ViewChild('gaugeRange') singleGauge; // FIX TYPE
  @Input() data: Device =
    { id: null, name: 'unknown device', timestamp: 0, percentage: 0, feature_flags: 0, status_flags: 0, fraction: {name: 'None'}};
  public DeviceStatusFlag =  DeviceStatusFlag;

  constructor(private languageService: LanguageService, private utils: UtilsService) { }

  public flag(state: number, flag: number): boolean { return !!(state & flag); }

  /**
   * Returns the correct CSS class names based on the indicator status
   */
  public getStatusFlagClass(status_flag: number, feature_flag: number, flag: number) {
    const indicator = this.getStatusFlag(status_flag, feature_flag, flag);
    switch (indicator) {
      case INDICATOR.DISABLED: return 'disabled'; // gray
      case INDICATOR.OK: return 'ok'; // green
      case INDICATOR.ERROR: return 'error'; // red
      case INDICATOR.OK_OFFLINE: return 'ok-dark'; // last known status was ok (device is offline), make dark green
      case INDICATOR.ERROR_OFFLINE: return 'error-dark'; // last known status was error (device is offline), make dark red
      default: return ''; // should not happen
    }
  }

  /**
   * Returns DISABLED when feature_flag is disabled (0)
   * Returns ERROR when feature_flag is enabled (1) AND status_flag is 1
   * Returns OK when feature_flag is enabled (1) AND status_flag is 0
   */
  public getStatusFlag(status_flag: number, feature_flag: number, flag: number) {
    const statusflag = this.flag(status_flag, flag);
    if (this.isOffline()) {
      // return INDICATOR.DISABLED; // make icons gray when device is offline
      return (statusflag ? INDICATOR.ERROR_OFFLINE : INDICATOR.OK_OFFLINE);
    }
    const featureflag = this.flag(feature_flag, flag);
    if (!featureflag) {
      return INDICATOR.DISABLED;
    } else {
      return (statusflag ? INDICATOR.ERROR : INDICATOR.OK);
    }
  }

  // Return the correct Font Awesome icon for the given event
  public getIcon(event: string) {
    let eventString = event;
    if (eventString === 'ContainerOn') {
      if (this.containerClass() === 'error' || this.containerClass() === 'error-dark') {
        eventString = 'ContainerGone';
      }
    }
    const eventObject: CompactorEvent = {timestamp: 0, event: eventString};
    return this.utils.getEventIcon(eventObject);
  }

  // Returns the correct CSS class for the given indicator using the above methods
  offlineClass()   { return this.getStatusFlagClass(this.data.status_flags, this.data.feature_flags, DeviceStatusFlag.OFFLINE); }
  emergencyClass() { return this.getStatusFlagClass(this.data.status_flags, this.data.feature_flags, DeviceStatusFlag.EMERGENCY); }
  errorClass()     { return this.getStatusFlagClass(this.data.status_flags, this.data.feature_flags, DeviceStatusFlag.ERROR); }
  containerClass() { return this.getStatusFlagClass(this.data.status_flags, this.data.feature_flags, DeviceStatusFlag.CONTAINER); }
  unpluggedClass() { return this.getStatusFlagClass(this.data.status_flags, this.data.feature_flags, DeviceStatusFlag.UNPLUGGED); }

  /**
   * When FULL bit is on (error, '1'): Red lamp
   * When BLUE LIGHT bit is on (error, '1'): Blue lamp
   * When both are off: Green lamp
   */
  blueLightClass() {
    const blue = this.getStatusFlagClass(this.data.status_flags, this.data.feature_flags, DeviceStatusFlag.BLUE_LIGHT);
    const full = this.getStatusFlagClass(this.data.status_flags, this.data.feature_flags, DeviceStatusFlag.FULL);
    return (full === 'error' ? full : 'blue ' + blue);
  }

  // Tool tip texts for the gauge indicators
  emergencyText() { return this.languageService.getTranslation(_('gauge.tooltips.emergency'))
                    + ': ' + this.descriptiveTooltip(this.emergencyClass(), 'emergency'); }
  errorText()     { return this.languageService.getTranslation(_('gauge.tooltips.hitch'))
                    + ': ' + this.descriptiveTooltip(this.errorClass(), 'error'); }
  containerText() { return this.languageService.getTranslation(_('gauge.tooltips.container'))
                    + ': ' + this.descriptiveTooltip(this.containerClass(), 'container'); }
  unpluggedText() { return this.languageService.getTranslation(_('gauge.tooltips.power'))
                    + ': ' + this.descriptiveTooltip(this.unpluggedClass(), 'unplugged'); }

  blueLightText() {
    if (this.deviceFull()) {
      return this.languageService.getTranslation(_('gauge.container')) + ': '
        + this.descriptiveTooltip(this.blueLightClass(), 'containerfull');
    } else {
      return this.languageService.getTranslation(_('gauge.container')) + ': '
        + this.descriptiveTooltip(this.blueLightClass(), 'almostfull');
    }
  }

  public deviceFull(): boolean {
    return this.getStatusFlag(this.data.status_flags, this.data.feature_flags, DeviceStatusFlag.FULL) === INDICATOR.ERROR;
  }

  // Make a more descritive tooltip
  private descriptiveTooltip(type: string, event: string) {
    switch (type) {
      case 'disabled': case 'blue disabled':
        return this.languageService.getTranslation(_('gauge.disabled')); // gray icons
      case 'ok': case 'blue ok': case 'ok-dark': default:
        switch (event) {
          case 'emergency': return this.languageService.getTranslation(_('common.no')); // EmergencyOff
          case 'error': return this.languageService.getTranslation(_('common.no')); // HitchOff
          case 'container': return this.languageService.getTranslation(_('gauge.tooltips.containerCoupled')); // ContainerOn
          case 'unplugged': return this.languageService.getTranslation(_('common.on')); // PowerOn
          case 'blueLight': return this.languageService.getTranslation(_('common.off')); // BlueLightOff
          case 'almostfull': return this.languageService.getTranslation(_('common.ok')); // Container Ok
          case 'containerfull': return this.languageService.getTranslation(_('common.off')); // This should probably never happen
        }
        break;
      case 'error': case 'blue error': case 'error-dark':
        switch (event) {
          case 'emergency': return this.languageService.getTranslation(_('common.yes')); // EmergencyOn
          case 'error': return this.languageService.getTranslation(_('common.on')); // HitchOn
          case 'container': return this.languageService.getTranslation(_('gauge.tooltips.containerGone')); // ContainerGone
          case 'unplugged': return this.languageService.getTranslation(_('common.off')); // PowerOff
          case 'blueLight': return this.languageService.getTranslation(_('common.on')); // BlueLight / AlmostFull
          case 'almostfull': return this.languageService.getTranslation(_('common.almostfull')); // BlueLight / AlmostFull
          case 'containerfull': return this.languageService.getTranslation(_('common.full')); // Container Full (red light)
        }
    }
    return 'Error'; // should not happen
  }

  public onlineIconClass() {
    return this.utils.RSS;
  }

  isOffline() { return this.flag(this.data.status_flags, DeviceStatusFlag.OFFLINE); }
  isOnline() { return !this.isOffline(); }

  isOnlineText() {
    return this.isOnline()
      ? this.languageService.getTranslation(_('gauge.online'))
      : this.languageService.getTranslation(_('gauge.offline'));
  }

  // Returns true when Device is Almost Full (Blue Light is on)
  public almostFull(): boolean {
    const threshold = this.threshold();
    const pct = this.data ? this.data.percentage : 0;
    return pct > threshold;
  }

  public threshold(): number {
    let threshold = 75;
    if (this.data && this.data.settings) {
      threshold = this.data.settings.almostfull_threshold;
    }
    // console.log("Almost full threshold - Device " + (device ? device.name : '') + ": " + threshold + "%");
    return threshold;
  }

  // Returns the fraction (fractiesoort / afvalstroom) for this device, if available
  public getFraction() {
    // this.testData();
    if (this.data.fraction) {
      return this.data.fraction.name;
    } else {
      return this.languageService.getTranslation(_('common.none'));
    }
  }

  // Returns the full readable name of the device for the tooltip (mouse hover)
  public getDeviceTitleTooltip() {
    return this.data.name + (this.data.address && this.data.address.city ? ' (' + this.data.address.city + ')' : '');
  }

  // Returns a shortened readable name of the device to be placed in the <h1> header
  // Old Format: NAME - LOCATION #DOCK, f.e.: HOR 311 - GOOR #22
  // New Format: NAME
  public getShortDeviceTitle() {
    let result = this.data.name; // Apparantly they want just the name now...
    if (result.length > 28) {
      result = result.substr(0, 28); // make name shorter (max length for the gauge titel is 33)
    }
    return result;
  }

  // Called after the view is completetely initialized so we can access the DOM and change the background image of the DIV
  // For more information about Conical Gradients see the ConicGradients.md
  ngAfterViewInit() {
    const online: boolean = this.isOnline();
    const dataURL = CustomGradient(online); // this is slow apparantly (~400+ ms)
    if (dataURL) {
      const element = this.singleGauge ? this.singleGauge.nativeElement : null;
      if (element) {
        // TODO: Make the sizes dynamic using the $range-radius that's currently in the CSS
        const newBackground = 'url(' + dataURL + ') left top / 150px 150px';
        element.style.background = newBackground;
      }
    }
  }
}
