import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { NewAuthService } from '../services/oauth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private oauthService: NewAuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    return this.verify(url);
  }

  private verify(url?: string) {
    if (this.oauthService && this.oauthService.hasToken()) {
      const token = this.oauthService.getToken();
      // console.log('[Auth Guard] We have a token, allowing access.');
      this.saveUrl(url);
      return true; // Allow access
    } else {
      this.oauthService.loginThroughStorage();
      // check if we can retrieve token from the localStorage
      if (this.oauthService.hasToken()) {
        // console.log('[Auth Guard] Retrieved token from localStorage');
        this.saveUrl(url);
        return true; // Allow access
      }
    }
    // console.log('[Auth Guard] We do not have a token, redirecting to sign-on');
    // this.oauthService.setSourceUrl(url); // Store the entry URL for redirection after sign-on
    // console.log("URL where we want to redirect to after sign-on: " + url);
    this.saveUrl(url);
    this.router.navigate(['/sign-on']); // Redirect to the actual sign-on
    return false; // Refuse access to requested original route
  }

  private saveUrl(url: string) {
    localStorage.setItem('url', url);
    // console.log('[Auth Guard] Saving url to: ' + url);
  }
}
